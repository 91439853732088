import {
  SchoolTimetableDaysModel,
  SchoolTimetableWithDays,
} from './../shared/models/school-timetable.model';
import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { SchoolModelCurriculum } from '../shared/models/school.model';
import { forkJoin, from, map, of, switchMap, take } from 'rxjs';
import {
  SchoolStudentDetailsModel,
  SchoolStudentModel,
  SchoolStudentModelConverter,
} from '../shared/models/school-student.model';
import { UserModel, UserModelConverter } from '../shared/models/user.model';
import {
  SchoolTeacherDetailModel,
  SchoolTeacherModel,
  SchoolTeacherModelConverter,
} from '../shared/models/school-teacher.model';
import {
  SchoolClassData,
  SchoolClassModel,
  SchoolClassModelConverter,
} from '../shared/models/school-class.model';
import { SchoolStudentAttendanceModelConverter } from '../shared/models/school-student-attendance.model';
import { SchoolStudentAssessmentModelConverter } from '../shared/models/school-student-assessment.model';
import {
  SchoolTeacherAttendanceModel,
  SchoolTeacherAttendanceModelConverter,
} from '../shared/models/school-teacher-attendance.model';
import {
  SchoolTimetableDaysModelConverter,
  SchoolTimetableModel,
  SchoolTimetableModelConverter,
} from '../shared/models/school-timetable.model';
import { SchoolClassSyllabusModelConverter } from '../shared/models/school-class-syllabus.model';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  private fireStore: Firestore = inject(Firestore);

  constructor() {}

  fetchStudents(school_doc_id: string) {
    let studentsQuery = query(
      collection(this.fireStore, 'school_student').withConverter(
        SchoolStudentModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    let schoolStudents$ = collectionData(studentsQuery);

    return schoolStudents$.pipe(
      switchMap((students: SchoolStudentModel[]) => {
        if (students.length) {
          return forkJoin(
            students.map((student: SchoolStudentModel) =>
              this.fetchUserDetails(student.user_id).pipe(
                map((userDoc) => {
                  if (userDoc.exists()) {
                    const userDetails = userDoc.data() as UserModel;
                    return {
                      student_doc: student,
                      student_details: userDetails,
                    } as SchoolStudentDetailsModel;
                  } else {
                    return {
                      student_doc: student,
                      student_details: {},
                    } as SchoolStudentDetailsModel;
                  }
                })
              )
            )
          );
        } else {
          return of([] as SchoolStudentDetailsModel[]);
        }
      })
    );
  }

  fetchStudentAttendance(class_ref: DocumentReference) {
    let studentAttendanceQuery = query(
      collection(this.fireStore, 'school_student_attendance').withConverter(
        SchoolStudentAttendanceModelConverter
      ),
      where('class_ref', '==', class_ref)
    );
    return collectionData(studentAttendanceQuery).pipe(take(1));
  }

  fetchStudentAssessments(class_ref: DocumentReference) {
    let studentAssessmentsQuery = query(
      collection(this.fireStore, 'school_student_assessment').withConverter(
        SchoolStudentAssessmentModelConverter
      ),
      where('class_ref', '==', class_ref)
    );

    return collectionData(studentAssessmentsQuery).pipe(take(1));
  }

  fetchTeachers(school_doc_id: string) {
    let teacherQuery = query(
      collection(this.fireStore, 'school_teacher').withConverter(
        SchoolTeacherModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    let schoolteachers$ = collectionData(teacherQuery);

    return schoolteachers$.pipe(
      switchMap((teachers: SchoolTeacherModel[]) => {
        if (teachers.length) {
          return forkJoin(
            teachers.map((teacher: SchoolTeacherModel) =>
              this.fetchUserDetails(teacher.user_id).pipe(
                switchMap((userDoc) => {
                  const userDetails = userDoc.exists()
                    ? (userDoc.data() as UserModel)
                    : ({} as UserModel);

                  return this.fetchTeacherAttendance(
                    school_doc_id,
                    teacher.user_id
                  ).pipe(
                    map((attendanceDocs: SchoolTeacherAttendanceModel[]) => {
                      const attendance =
                        attendanceDocs.length > 0 ? attendanceDocs : [];

                      let schoolTeacherDetails: SchoolTeacherDetailModel = {
                        teacher_details: userDetails,
                        teacher_doc: teacher,
                        teacherAttendance: attendance,
                      };

                      return schoolTeacherDetails;
                    })
                  );
                })
              )
            )
          );
        } else {
          return of([] as SchoolTeacherDetailModel[]);
        }
      })
    );
  }

  fetchTeacherAttendance(school_doc_id: string, teacher_id: string) {
    let teacherAttendanceQuery = query(
      collection(this.fireStore, `school_teacher_attendance`).withConverter(
        SchoolTeacherAttendanceModelConverter
      ),
      where('teacher_id', '==', teacher_id),
      where('school_id', '==', school_doc_id)
    );
    return collectionData(teacherAttendanceQuery).pipe(take(1));
  }

  fetchClasses(school_doc_id: string, curriculum: SchoolModelCurriculum) {
    let classesQuery = query(
      collection(
        this.fireStore,
        `schools/${school_doc_id}/classes`
      ).withConverter(SchoolClassModelConverter),
      where('deleted', '==', false),
      where('active', '==', true),
      where('curriculum.end_month', '==', curriculum.end_month),
      where('curriculum.start_month', '==', curriculum.start_month),
      where('curriculum.start_year', '==', curriculum.start_year),
      where('curriculum.end_year', '==', curriculum.end_year)
    );
    let classes$ = collectionData(classesQuery);

    return classes$.pipe(
      switchMap((classes: SchoolClassModel[]) => {
        if (classes.length) {
          const classObservables = classes.map(
            (classDetails: SchoolClassModel) => {
              return forkJoin({
                class_details: of(classDetails),
                class_assessments: this.fetchStudentAssessments(
                  classDetails.docRef!
                ),
                class_attendance: this.fetchStudentAttendance(
                  classDetails.docRef!
                ),
                class_syllabus: this.fetchClassSyllabus(
                  school_doc_id,
                  classDetails.docRef?.id as string
                ),
              });
            }
          );
          return forkJoin(classObservables);
        } else {
          return of([] as SchoolClassData[]);
        }
      })
    );
  }

  fetchAdmins(school_doc_id: string) {
    let adminsQuery = query(
      collection(this.fireStore, 'school_admin'),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    return collectionData(adminsQuery);
  }

  fetchClassSyllabus(school_id: string, class_doc_id: string) {
    let classSyllabusQuery = query(
      collection(
        this.fireStore,
        `schools/${school_id}/classes/${class_doc_id}/syllabus`
      ).withConverter(SchoolClassSyllabusModelConverter)
    );

    return collectionData(classSyllabusQuery).pipe(take(1));
  }

  fetchUserDetails(doc_id: string) {
    let userDocRef = doc(this.fireStore, `users/${doc_id}`).withConverter(
      UserModelConverter
    );

    return from(getDoc(userDocRef)).pipe(take(1));
  }

  fetchTimeTablesAlongWithDays(
    school_id: string,
    curriculum: SchoolModelCurriculum
  ) {
    let schoolTimetablesQuery = query(
      collection(
        this.fireStore,
        `schools/${school_id}/timetable`
      ).withConverter(SchoolTimetableModelConverter),
      where('curriculum.end_month', '==', curriculum.end_month),
      where('curriculum.start_month', '==', curriculum.start_month),
      where('curriculum.start_year', '==', curriculum.start_year),
      where('curriculum.end_year', '==', curriculum.end_year)
    );

    let schoolTimetables$ = collectionData(schoolTimetablesQuery);

    return schoolTimetables$.pipe(
      switchMap((timetables: SchoolTimetableModel[]) => {
        if (!timetables || timetables.length === 0) {
          // If no timetables exist, return an empty array or handle it as needed
          return of([] as SchoolTimetableWithDays[]);
        }
        const timetablesWithDays$ = timetables.map((timetable) =>
          this.fetchTimetableDays(school_id, timetable.docId!).pipe(
            map((days: { [key: string]: SchoolTimetableDaysModel }) => {
              return {
                timetable: timetable,
                days: days,
              } as SchoolTimetableWithDays;
            })
          )
        );

        return forkJoin(timetablesWithDays$);
      })
    );
  }

  fetchTimetableDays(school_id: string, timetable_doc_id: string) {
    let timetableDaysQuery = query(
      collection(
        this.fireStore,
        `schools/${school_id}/timetable/${timetable_doc_id}/days`
      ).withConverter(SchoolTimetableDaysModelConverter)
    );

    return collectionData(timetableDaysQuery).pipe(
      take(1),
      map((days: SchoolTimetableDaysModel[]) => {
        const daysObject: { [key: string]: SchoolTimetableDaysModel } = {};
        days.forEach((day: SchoolTimetableDaysModel) => {
          daysObject[day.docId!] = day;
          delete day.docId;
        });
        return daysObject;
      })
    );
  }
}
