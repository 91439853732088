import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { ThemeManager } from '../../services/theme-manager.service';
import { AsyncPipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dash-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    AsyncPipe,
    MatMenuModule,
  ],
  templateUrl: './dash-header.component.html',
  styleUrl: './dash-header.component.scss',
})
export class DashHeaderComponent {
  private authSrvc_: AuthService = inject(AuthService);

  themeManager = inject(ThemeManager);

  private router: Router = inject(Router);

  isDark$ = this.themeManager.isDark$;

  changeTheme(theme: string) {
    this.themeManager.changeTheme(theme);
  }

  signOut() {
    this.authSrvc_.logOut();
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }
}
