import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../services/local-storage.service';
import { SchoolClassService } from '../../../services/school-class.service';
import { SchoolModelCurriculum } from '../../../shared/models/school.model';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../shared/decorators/unsubscribe.decorator';
import { SnackbarService } from '../../../services/snackbar.service';
import { SchoolTimetableWithDays } from '../../../shared/models/school-timetable.model';
import { SchoolTeacherDetailModel } from '../../../shared/models/school-teacher.model';
import { SchoolClassModel } from '../../../shared/models/school-class.model';
import { UpperCasePipe } from '@angular/common';
import { SchoolStudentDetailsModel } from '../../../shared/models/school-student.model';
import { SchoolStudentAssessmentModel } from '../../../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModel } from '../../../shared/models/school-student-attendance.model';
import { UtilsService } from '../../../services/utils.service';
import { SchoolClassSyllabusModel } from '../../../shared/models/school-class-syllabus.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ClassStudentsListComponent } from '../inner-pages/class-db/class-students-list/class-students-list.component';
import { ClassSubjectStatsComponent } from '../inner-pages/class-db/class-subject-stats/class-subject-stats.component';
import { ClassTimetableComponent } from '../inner-pages/class-db/class-timetable/class-timetable.component';

@Component({
  selector: 'class-dashboard',
  standalone: true,
  imports: [
    ClassTimetableComponent,
    UpperCasePipe,
    ClassSubjectStatsComponent,
    MatProgressSpinnerModule,
    ClassStudentsListComponent,
    MatIconModule,
  ],
  templateUrl: './class-dashboard.component.html',
  styleUrl: './class-dashboard.component.scss',
})
@unsubscribe()
export class ClassDashboardComponent implements OnInit, OnDestroy {
  @Input() class_id!: string;

  classDetails!: SchoolClassModel;

  school_id!: string;

  school_curriculum!: SchoolModelCurriculum;

  classStudents$!: Subscription;
  classTeachers$!: Subscription;
  classTimetable$!: Subscription;
  classSyllabus$!: Subscription;

  classStudents: SchoolStudentDetailsModel[] = [];
  classAssessments: SchoolStudentAssessmentModel[] = [];
  classAttendance: SchoolStudentAttendanceModel[] = [];
  classSyllabusData: SchoolClassSyllabusModel[] = [];

  classPerformance: number = 0;
  classAttendancePercentage: number = 0;

  classTimetable!: SchoolTimetableWithDays;
  classTeachers: SchoolTeacherDetailModel[] = [];

  classTeacherName: string = '';

  classDataLoaded!: boolean;

  syllabusProgress: number = 0;

  private localStorageSrvc_: LocalStorageService = inject(LocalStorageService);

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  private classSrvc_: SchoolClassService = inject(SchoolClassService);

  private utilsSrvc_: UtilsService = inject(UtilsService);

  private router: Router = inject(Router);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.class_id = atob(this.class_id);
    console.log(this.class_id);
    this.school_id = this.localStorageSrvc_.getItem(
      'selected_school_id'
    )! as string;
    this.school_curriculum = this.localStorageSrvc_.getItem(
      'school_curriculum'
    ) as SchoolModelCurriculum;

    this.classDetails = this.localStorageSrvc_.getItem(
      'selected_class'
    ) as SchoolClassModel;

    this.classDataLoaded = false;
    this.classStudents$ = this.classSrvc_
      .fetchClassStudents(this.school_id, this.class_id)
      .subscribe({
        next: (el) => {
          console.log(el);
          this.classDataLoaded = false;
          this.classStudents = el.class_students;
          this.classAssessments = el.class_assessments;
          this.classAttendance = el.class_attendance;
          this.classAttendancePercentage = this.utilsSrvc_.calulateAttendance(
            this.classAttendance
          );
          this.classPerformance = this.utilsSrvc_.calculatePerformance(
            this.classAssessments
          );
          this.classTimetable$ = this.classSrvc_
            .fetchClassTimetableWithDays(
              this.school_id,
              this.class_id,
              this.school_curriculum
            )
            .subscribe({
              next: (el) => {
                console.log(el);
                this.classDataLoaded = false;
                this.classTimetable = el[0];
                this.classTeachers$ = this.classSrvc_
                  .fetchClassTeachers(this.school_id, this.class_id)
                  .subscribe({
                    next: (el) => {
                      console.log(el);
                      this.classDataLoaded = false;
                      this.classTeachers = el;
                      let classTeacherFound = this.classDetails.teachers.find(
                        (teacher) => teacher.class_teacher
                      );
                      if (classTeacherFound) {
                        let classTeacherDetails = this.classTeachers.find(
                          (teacher) =>
                            teacher.teacher_details.docId ==
                            classTeacherFound.teacher_id
                        );
                        if (classTeacherDetails) {
                          this.classTeacherName = this.utilsSrvc_.toTitleCase(
                            classTeacherDetails.teacher_details.name.join(' ')
                          );
                        }
                      }
                      this.classSyllabus$ = this.classSrvc_
                        .fetchClassSyllabusDocs(this.school_id, this.class_id)
                        .subscribe({
                          next: (value: SchoolClassSyllabusModel[]) => {
                            this.classDataLoaded = true;
                            this.classSyllabusData = value;
                            this.syllabusProgress =
                              this.utilsSrvc_.overallSyllabusCompletion(
                                this.classSyllabusData
                              );
                          },
                          error: () => {
                            this.classDataLoaded = true;
                            this.snackbarSrvc_.openErrorSnackbar(
                              'Error while processing the request'
                            );
                          },
                          complete: () => {
                            this.classDataLoaded = true;
                          },
                        });
                    },
                    error: (err) => {
                      console.log(err);
                      this.classDataLoaded = true;
                      this.snackbarSrvc_.openErrorSnackbar(
                        'Error while processing the request'
                      );
                    },
                    complete: () => {
                      this.classDataLoaded = true;
                    },
                  });
              },
              error: () => {
                this.classDataLoaded = true;
                this.snackbarSrvc_.openErrorSnackbar(
                  'Error while processing the request'
                );
              },
              complete: () => {
                this.classDataLoaded = true;
              },
            });
        },
        error: (err) => {
          this.classDataLoaded = true;
          this.snackbarSrvc_.openErrorSnackbar(
            'Error while processing the request'
          );
        },
        complete: () => {
          this.classDataLoaded = true;
        },
      });
  }

  navigateBackToSchoolDB() {
    this.router.navigate([`/dashboard/school/${btoa(this.school_id)}`]);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
