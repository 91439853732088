import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  let authSrvc_: AuthService = inject(AuthService);
  let router: Router = inject(Router);

  let user = JSON.parse(localStorage.getItem('user')!);
  let superAdmin = JSON.parse(localStorage.getItem('superAdmin')!);

  // If both user and superAdmin exist
  if (!!user && !!superAdmin) {
    // If the current route is the 'sign-in' page, redirect to the dashboard
    if (state.url === '/sign-in') {
      return router.parseUrl('/dashboard'); // Redirect to the dashboard
    }
    return true; // Allow access for other routes
  } else {
    // If user or superAdmin is missing, log out and redirect to sign-in
    authSrvc_.logOut();
    return router.parseUrl('sign-in');
  }
};
