import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { ThemeManager } from '../services/theme-manager.service';
import { RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [AsyncPipe, RouterOutlet, MatButtonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'amns-superadmin';

  private themeSrvc_: ThemeManager = inject(ThemeManager);

  constructor() {
    // this.authSrvc_.monitorInactivity();
    this.themeSrvc_.changeTheme('auto');
  }
}
