import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../services/auth.service';
import { RouterLink } from '@angular/router';
import { SnackbarService } from '../../../services/snackbar.service';
import { Subscription, take } from 'rxjs';
import { unsubscribe } from '../../../shared/decorators/unsubscribe.decorator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    MatProgressSpinnerModule,
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
})
@unsubscribe()
export class SignInComponent implements OnInit, OnDestroy {
  hide = true;

  private authSrvc_: AuthService = inject(AuthService);

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  signInSubscription!: Subscription;

  signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  loading!: boolean;

  constructor() {}

  ngOnInit(): void {
    // this.authSrvc_.logOut();
    this.authSrvc_.clearAllExceptOneKey('amns-hurrey');
  }

  get email() {
    return this.signInForm.get('email') as FormControl;
  }

  get password() {
    return this.signInForm.get('password') as FormControl;
  }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Please enter your email';
    } else if (this.email.hasError('email')) {
      return 'Email entered is invalid';
    }

    return '';
  }

  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'Please enter your password';
    } else if (this.password.hasError('minlength')) {
      return 'Password consists of minimum 6 characters';
    }

    return '';
  }

  fetchFBUser() {
    this.loading = true;
    this.signInSubscription = this.authSrvc_
      .signInWithEmailPassword(this.email.value, this.password.value)
      .pipe(take(1))
      .subscribe({
        next: (data: any) => {
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          if (err.code == 'auth/email-already-exists') {
            this.snackbarSrvc_.openErrorSnackbar(
              'The provided email is already in use.Please use a different email'
            );
          } else if (err.code == 'auth/invalid-email') {
            this.snackbarSrvc_.openErrorSnackbar(
              'The entered email is invalid.Please enter a valid email'
            );
          } else if (err.code == 'auth/too-many-requests') {
            this.snackbarSrvc_.openErrorSnackbar(
              'Too many requests.Please try after some time'
            );
          } else if (err.code == 'auth/user-not-found') {
            this.snackbarSrvc_.openErrorSnackbar(
              'User not found. Please contact us at support@hurreytech.com'
            );
          } else if (err.code == 'auth/wrong-password') {
            this.snackbarSrvc_.openErrorSnackbar(
              'Invalid Password.Please retry again or reset the password'
            );
          } else {
            this.snackbarSrvc_.openErrorSnackbar(
              'Error while processing the request'
            );
          }
          // this.signInForm.reset();
          // this.signInForm.markAsUntouched();
        },
      });
  }

  ngOnDestroy(): void {}
}
