import { TitleCasePipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { SchoolTeacherDetailModel } from '../../../../../shared/models/school-teacher.model';
import {
  SchoolTimetableWithDays,
  ClassTimetablePeriodForDisplay,
  TimetablePeriodModel,
  TimetablePeriodTeacherModel,
} from '../../../../../shared/models/school-timetable.model';

@Component({
  selector: 'class-timetable',
  standalone: true,
  imports: [MatTabsModule, TitleCasePipe, MatIconModule],
  templateUrl: './class-timetable.component.html',
  styleUrl: './class-timetable.component.scss',
})
export class ClassTimetableComponent implements OnInit, OnChanges {
  @ViewChild('daysTab') daysTab?: MatTabGroup;
  @Input() classTimetableData!: SchoolTimetableWithDays;

  @Input() classTeachers!: SchoolTeacherDetailModel[];

  @Input() class_id: string = '';

  classTimetablePeriods: {
    [key: string]: ClassTimetablePeriodForDisplay[];
  } = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  selectedDayClassWisePeriods: ClassTimetablePeriodForDisplay[] = [];

  selectedIndex: number = -1;

  weekDays: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  today: Date = new Date();

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['classTeachers'] && !!changes['classTimetableData']) {
      this.classTeachers = changes['classTeachers'].currentValue;
    }
    if (!!changes['classTimetableData']) {
      this.classTimetableData = changes['classTimetableData'].currentValue;
    }

    if (this.selectedIndex == -1) {
      this.extractClassWiseDayPeriods((this.today.getDay() + 6) % 7);
    } else {
      this.extractClassWiseDayPeriods(this.selectedIndex);
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  extractClassWiseDayPeriods(index: number) {
    for (const key in this.classTimetablePeriods) {
      if (
        Object.prototype.hasOwnProperty.call(this.classTimetablePeriods, key)
      ) {
        let day = key;
        let periods = this.classTimetablePeriods[key];
        let foundPeriods =
          this.classTimetableData?.days[`${day.toLowerCase()}`].periods;
        let foundPeriodsForDisplay: ClassTimetablePeriodForDisplay[] = [];
        if (foundPeriods) {
          foundPeriodsForDisplay = foundPeriods.map(
            (foundPeriod: TimetablePeriodModel) => {
              let { teacher, subject } = this.attachTeacherANDSubject(
                foundPeriod.teachers
              );
              let obj: ClassTimetablePeriodForDisplay = {
                start: this.convertTime(foundPeriod.start),
                end: this.convertTime(foundPeriod.end),
                period: foundPeriod.period,
                type: foundPeriod.type.toLowerCase(),
                teacher: teacher,
                subject: subject,
              };
              return obj;
            }
          );
          periods.push(...foundPeriodsForDisplay);
        }
      }
    }

    this.onTabSelected(index);
  }

  attachTeacherANDSubject(teachers: TimetablePeriodTeacherModel[]) {
    let data = { teacher: '', subject: '' };
    let classTeacherPeriod = teachers.find(
      (teacher) => teacher.class_id == this.class_id
    );
    if (classTeacherPeriod) {
      data.subject = classTeacherPeriod.subject;
      let teacherDoc = this.classTeachers.find(
        (teacher) =>
          teacher.teacher_details.docId == classTeacherPeriod.teacher_id
      );
      if (teacherDoc) {
        data.teacher = teacherDoc.teacher_details.name.join(' ');
      }
    }
    return data;
  }

  convertTime(timeString: string): string {
    const [hours, minutes] = timeString.split(':');
    const hour = parseInt(hours, 10);
    const formattedHours = hour > 12 ? hour - 12 : hour;
    const formattedMinutes = minutes.padStart(2, '0');
    const meridian = hour >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${formattedMinutes} ${meridian}`;
  }

  onTabSelected(dayIndex: number) {
    this.selectedIndex = dayIndex;
    if (this.daysTab) {
      this.daysTab.focusTab(dayIndex);
      this.daysTab?._tabHeader._scrollToLabel(dayIndex);
    }
    this.selectedDayClassWisePeriods =
      this.classTimetablePeriods[`${this.weekDays[dayIndex].toLowerCase()}`];
  }
}
