import { SchoolClassData } from './../shared/models/school-class.model';
import { inject, Injectable } from '@angular/core';
import { SchoolStudentDetailsModel } from '../shared/models/school-student.model';
import { SchoolTeacherDetailModel } from '../shared/models/school-teacher.model';
import { SchoolTimetableWithDays } from '../shared/models/school-timetable.model';
import { SCHOOL_RECOMMENDATIONS } from '../shared/models/school-recommendations.enum';
import { SchoolNotification } from '../shared/models/school-notifications.model';
import { UtilsService } from './utils.service';
import { SchoolStudentAttendanceModel } from '../shared/models/school-student-attendance.model';

@Injectable({
  providedIn: 'root',
})
export class SchoolNotificationService {
  constructor() {}

  private utilsSrvc_: UtilsService = inject(UtilsService);

  getNotifications(
    schoolClassData: SchoolClassData[],
    schoolStudentData: SchoolStudentDetailsModel[],
    schoolTeacherData: SchoolTeacherDetailModel[],
    schoolTimetableData: SchoolTimetableWithDays[]
  ) {
    const school_notifications: SchoolNotification[] = [];

    if (schoolClassData.length == 0) {
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_NOT_FOUND,
        message: 'No class has been onboarded',
      });
    } else {
      this.add_class_notifications(
        school_notifications,
        schoolClassData,
        schoolTimetableData,
        [
          ...new Set(
            schoolStudentData
              .map((a) => a.student_doc.class_ref?.id as string)
              .flat()
          ),
        ]
      );
    }
    if (schoolTeacherData.length == 0) {
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_FOUND,
        message: 'No teacher has been onboarded',
      });
    } else {
      this.add_teacher_notifications(
        school_notifications,
        schoolTeacherData,
        schoolTimetableData
      );
    }
    if (schoolStudentData.length == 0) {
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.STUDENTS_NOT_FOUND,
        message: 'No student has been onboarded',
      });
    }
    if (schoolTimetableData.length == 0) {
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.TIMETABLES_NOT_FOUND,
        message: 'No timetable has been created by the admin',
      });
    }

    return school_notifications;
  }

  add_teacher_notifications(
    school_notifications: SchoolNotification[],
    schoolTeacherData: SchoolTeacherDetailModel[],
    schoolTimetableData: SchoolTimetableWithDays[]
  ) {
    let today = new Date();
    let teacher_class_map_msg: string[] = [];
    let teacher_tt_map_msg: string[] = [];
    let teacher_not_logged_in: string[] = [];
    let teacher_not_logged_in_today: string[] = [];

    for (let data of schoolTeacherData) {
      try {
        // checking if teacher is mapped to class
        if (!data.teacher_doc.classes.length) {
          teacher_class_map_msg.push(
            this.utilsSrvc_.toTitleCase(data.teacher_details.name[0])
          );
        }
        // checking if teacher is mapped to timetable
        let teacher_mapped_to_timetable = false;
        if (schoolTimetableData.length) {
          for (let tt of schoolTimetableData) {
            for (let day in tt.days) {
              const periods = tt.days[day].periods;
              for (let period of periods) {
                for (let teacher of period.teachers) {
                  if (teacher.teacher_id == data.teacher_doc.user_id!) {
                    teacher_mapped_to_timetable = true;
                    break;
                  }
                }
              }
            }
          }
          if (!teacher_mapped_to_timetable) {
            teacher_tt_map_msg.push(
              this.utilsSrvc_.toTitleCase(data.teacher_details.name[0])
            );
          }
        }
        // checking if teacher has any attendance records
        if (!data.teacherAttendance) {
          teacher_not_logged_in.push(
            this.utilsSrvc_.toTitleCase(data.teacher_details.name[0])
          );
        } else {
          // checking if teacher has not taken attendance for today
          let todayTeacherDocs = data.teacherAttendance.filter(
            (att) =>
              att.attendance_date.day == today.getDate() &&
              att.attendance_date.month == today.getMonth() + 1 &&
              att.attendance_date.year == today.getFullYear()
          );
          if (!todayTeacherDocs) {
            teacher_not_logged_in_today.push(
              this.utilsSrvc_.toTitleCase(data.teacher_details.name[0])
            );
          }
        }
      } catch (error) {}
    }
    if (teacher_class_map_msg.length) {
      if (teacher_class_map_msg.length == schoolTeacherData.length) {
        school_notifications.push({
          message: 'No teacher has been assigned to classroom',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_MAPPED_TO_CLASS,
        });
      } else {
        school_notifications.push({
          message:
            teacher_class_map_msg.join(',') +
            ' has not been assigned to any class',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_MAPPED_TO_CLASS,
        });
      }
    }
    if (teacher_tt_map_msg.length) {
      if (teacher_tt_map_msg.length == schoolTeacherData.length) {
        school_notifications.push({
          message: 'No teacher has been assigned to timetable',
          icon: SCHOOL_RECOMMENDATIONS.TIMETABLE_NOT_MAPPED_TO_TEACHER,
        });
      } else {
        school_notifications.push({
          message:
            teacher_tt_map_msg.join(',') +
            ' has not been assigned to any timetable',
          icon: SCHOOL_RECOMMENDATIONS.TIMETABLE_NOT_MAPPED_TO_TEACHER,
        });
      }
    }
    if (teacher_not_logged_in.length) {
      if (teacher_not_logged_in.length == schoolTeacherData.length) {
        school_notifications.push({
          message: 'All the teachers are yet to login',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_LOGGED_IN,
        });
      } else {
        school_notifications.push({
          message: teacher_not_logged_in.join(',') + ' yet to login',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_LOGGED_IN,
        });
      }
    }

    if (teacher_not_logged_in_today.length) {
      if (teacher_not_logged_in_today.length == schoolTeacherData.length) {
        school_notifications.push({
          message: 'No teacher has been logged in today',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_LOGGED_IN_TODAY,
        });
      } else {
        school_notifications.push({
          message:
            teacher_not_logged_in_today.join(',') + ' did not login today',
          icon: SCHOOL_RECOMMENDATIONS.TEACHER_NOT_LOGGED_IN_TODAY,
        });
      }
    }
  }

  add_class_notifications(
    school_notifications: SchoolNotification[],
    schoolClassData: SchoolClassData[],
    schoolTimetableData: SchoolTimetableWithDays[],
    uniqueClassIDsFromStudents: string[]
  ) {
    SCHOOL_RECOMMENDATIONS.CLASS_ATTENDANCE_LOW;
    SCHOOL_RECOMMENDATIONS.CLASS_ATTENDANCE_NOT_TAKEN;
    SCHOOL_RECOMMENDATIONS.CLASS_PERFORMANCE_LOW;
    SCHOOL_RECOMMENDATIONS.CLASS_ASSESSMENTS_NOT_TAKEN;

    let today = new Date();

    let class_perf_low: string[] = [];
    let class_assessments_not_taken: string[] = [];
    let class_attendance_low: string[] = [];
    let class_attendance_not_taken: string[] = [];
    let class_timetable_not_mapped: string[] = [];
    let class_has_no_students: string[] = [];

    //
    const uniqueClassIds: string[] = [
      ...new Set(
        schoolTimetableData.map((a) => a.timetable.class_doc_ids).flat()
      ),
    ];

    console.log(uniqueClassIDsFromStudents);

    for (let classdata of schoolClassData) {
      if (
        !uniqueClassIds.includes(classdata.class_details.docRef?.id as string)
      ) {
        class_timetable_not_mapped.push(
          this.utilsSrvc_.toTitleCase(
            `${classdata.class_details.name} ${classdata.class_details.section}`
          )
        );
      }

      if (
        !uniqueClassIDsFromStudents.includes(
          classdata.class_details.docRef?.id as string
        )
      ) {
        class_has_no_students.push(
          this.utilsSrvc_.toTitleCase(
            `${classdata.class_details.name} ${classdata.class_details.section}`
          )
        );
      }

      if (!classdata.class_assessments.length) {
        class_assessments_not_taken.push(
          this.utilsSrvc_.toTitleCase(
            `${classdata.class_details.name} ${classdata.class_details.section}`
          )
        );
      } else {
        let performance = this.utilsSrvc_.calculatePerformance(
          classdata.class_assessments
        );
        if (performance < 40) {
          class_perf_low.push(
            this.utilsSrvc_.toTitleCase(
              `${classdata.class_details.name} ${classdata.class_details.section}`
            )
          );
        }
      }

      if (classdata.class_attendance.length > 0) {
        let attendancePercentage = this.utilsSrvc_.calulateAttendance(
          classdata.class_attendance
        );
        if (attendancePercentage < 40) {
          class_attendance_low.push(
            this.utilsSrvc_.toTitleCase(
              `${classdata.class_details.name} ${classdata.class_details.section}`
            )
          );
        }
        let todaysAttendance = classdata.class_attendance.filter(
          (attendanceDoc: SchoolStudentAttendanceModel) =>
            attendanceDoc.attendance_date.day == today.getDate() &&
            attendanceDoc.attendance_date.month == today.getMonth() + 1 &&
            attendanceDoc.attendance_date.year == today.getFullYear()
        );

        if (!todaysAttendance.length) {
          class_attendance_not_taken.push(
            this.utilsSrvc_.toTitleCase(
              `${classdata.class_details.name} ${classdata.class_details.section}`
            )
          );
        }
      } else {
        class_attendance_not_taken.push(
          this.utilsSrvc_.toTitleCase(
            `${classdata.class_details.name} ${classdata.class_details.section}`
          )
        );
      }
    }

    if (class_timetable_not_mapped.length) {
      class_timetable_not_mapped = this.utilsSrvc_.sortClasses(
        class_timetable_not_mapped,
        'asc'
      );
      let message = '';
      if (class_timetable_not_mapped.length == schoolClassData.length) {
        message = 'No class is assosciated to timetable';
      } else if (class_timetable_not_mapped.length == 1) {
        message =
          'Class ' +
          class_timetable_not_mapped.join(',') +
          ' is not assosciated with timetable';
      } else {
        message =
          'Classes ' +
          class_timetable_not_mapped.join(',') +
          ' are not assosciated with timetable';
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.TIMETABLE_NOT_MAPPED_TO_CLASS,
        message: message,
      });
    }

    if (class_has_no_students.length) {
      class_has_no_students = this.utilsSrvc_.sortClasses(
        class_has_no_students,
        'asc'
      );
      let message = '';
      if (class_has_no_students.length == schoolClassData.length) {
        message = 'No class has students mapped';
      } else if (class_has_no_students.length == 1) {
        message =
          'Class ' + class_has_no_students.join(',') + ' has no students';
      } else {
        message =
          'Classes ' + class_has_no_students.join(',') + ' have no students';
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_HAS_NO_STUDENTS,
        message: message,
      });
    }

    if (class_assessments_not_taken.length) {
      class_assessments_not_taken = this.utilsSrvc_.sortClasses(
        class_assessments_not_taken,
        'asc'
      );
      let message = '';
      if (class_assessments_not_taken.length == schoolClassData.length) {
        message = 'No assessment has been conducted in any of the classes';
      } else if (class_has_no_students.length == 1) {
        message =
          'No assessment has been conducted in Class ' +
          class_has_no_students.join(',');
      } else {
        message =
          'No assessment has been conducted in Classes ' +
          class_has_no_students.join(',');
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_ASSESSMENTS_NOT_TAKEN,
        message: message,
      });
    }

    if (class_attendance_low.length) {
      class_attendance_low = this.utilsSrvc_.sortClasses(
        class_attendance_low,
        'asc'
      );
      let message = '';
      if (class_attendance_low.length == schoolClassData.length) {
        message = 'All the classes have low attendance';
      } else if (class_attendance_low.length == 1) {
        message = class_attendance_low.join(',') + ' has low attendance';
      } else {
        message =
          'Classes ' + class_attendance_low.join(',') + ' have low attendance';
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_ATTENDANCE_LOW,
        message: message,
      });
    }

    if (class_attendance_not_taken.length) {
      class_attendance_not_taken = this.utilsSrvc_.sortClasses(
        class_attendance_not_taken,
        'asc'
      );
      let message = '';
      if (class_attendance_not_taken.length == schoolClassData.length) {
        message = 'No attendance has been taken in the school today';
      } else if (class_attendance_not_taken.length == 1) {
        message =
          'No attendance has been taken in class ' +
          class_attendance_not_taken.join(',');
      } else {
        message =
          'No attendance has been taken in classes ' +
          class_attendance_not_taken.join(',');
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_ATTENDANCE_NOT_TAKEN,
        message: message,
      });
    }

    if (class_perf_low.length) {
      class_perf_low = this.utilsSrvc_.sortClasses(class_perf_low, 'asc');
      let message = '';
      if (class_perf_low.length == schoolClassData.length) {
        message = 'All the classes in school are having low performance';
      } else if (class_perf_low.length == 1) {
        message = 'Class ' + class_perf_low.join(',') + ' is performing low';
      } else {
        message = 'Classes ' + class_perf_low.join(',') + ' are performing low';
      }
      school_notifications.push({
        icon: SCHOOL_RECOMMENDATIONS.CLASS_PERFORMANCE_LOW,
        message: message,
      });
    }
  }
}
