@if(!isObjectEmpty(superAdminDoc)){
@if (schoolsDataLoaded) {

@if (superAdminSchools.length) {
<div class="h-full w-full p-4 md:p-6 flex flex-col gap-5">

  @if(!isObjectEmpty(superAdminUserDoc)){
  @if(superAdminUserDoc.name.length!=0){
  <div class="flex flex-col gap-2">
    <p style="margin-bottom: 0px !important;">{{returnSalutation()}}</p>
    <strong class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{superAdminUserDoc.name.join('
      ')|titlecase}}</strong>
  </div>
  }
  }


  @defer(on viewport){
  <superadmin-counter [superAdminSchools]="superAdminSchools" [castedHours]="castedHours"></superadmin-counter>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }


  @defer(on viewport){
  <superadmin-schools-list [superAdminSchools]="superAdminSchools"></superadmin-schools-list>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }


  @defer (on viewport) {
  <superadmin-students-list [superAdminSchools]="superAdminSchools"></superadmin-students-list>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }

  @defer (on viewport) {
  <superadmin-teachers-list [superAdminSchools]="superAdminSchools"></superadmin-teachers-list>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }

  @defer (on viewport) {
  <superadmin-admins-list [superAdminSchools]="superAdminSchools"></superadmin-admins-list>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }


  @defer (on viewport) {
  <superadmin-schools-visuals [superAdminSchools]="superAdminSchools"></superadmin-schools-visuals>
  }@placeholder(minimum 500ms) {
  <div class="animate-pulse flex flex-col gap-4 w-full">
    <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
    <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
  </div>
  }


  @defer (on viewport) {
    <super-admin-casting-visuals [superAdminSchools]="superAdminSchools" (castedHours)="getCastedHours($event)"></super-admin-casting-visuals>
    }@placeholder(minimum 500ms) {
    <div class="animate-pulse flex flex-col gap-4 w-full">
      <div class="h-4 md:h-6 w-[30%] bg-ink_light rounded-lg"></div>
      <div class="h-4 md:h-6 w-[65%] bg-ink_light rounded-lg"></div>
      <div class="h-4 md:h-6 w-[50%] bg-ink_light rounded-lg"></div>
      <div class="h-4 md:h-6 w-[90%] bg-ink_light rounded-lg"></div>
      <div class="h-4 md:h-6 w-[80%] bg-ink_light rounded-lg"></div>
      <div class="h-4 md:h-6 w-[60%] bg-ink_light rounded-lg"></div>
    </div>
    }

</div>
}@else {
<div class="h-full flex flex-col justify-center items-center">
  <img src="../../../assets/images/no_data.svg" alt="No School Data Found" height="300" width="300">
  <p>No School data found</p>
</div>
}
}@else {
<div class="h-full w-full flex justify-center items-center">
  <mat-spinner color="primary"></mat-spinner>
</div>
}
}@else {
<div class="h-full flex flex-col justify-center items-center">
  <img src="../../../assets/images/no_data.svg" alt="No SuperAdmin Data Found" height="300" width="300">
  <p>No Superadmin data found</p>
</div>
}
