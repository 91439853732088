<mat-toolbar>
  <img class="hurrey-logo" src="../../assets/images/amns_logo.webp" alt="hurrey-logo" (click)="navigateToDashboard()">
  <span class="example-spacer"></span>
  <button mat-icon-button class="theme-toggle" aria-label="Change theme" [matMenuTriggerFor]="themeMenu">
    <mat-icon>
      {{(isDark$ | async) === true ? "dark_mode" : "light_mode" }}
    </mat-icon>
  </button>
  <mat-menu #themeMenu="matMenu">
    <button mat-menu-item (click)="changeTheme('auto')">System</button>
    <button mat-menu-item (click)="changeTheme('light')">Light</button>
    <button mat-menu-item (click)="changeTheme('dark')">Dark</button>
  </mat-menu>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="signOut()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>