import { Component } from '@angular/core';
import { DashHeaderComponent } from '../../../core/dash-header/dash-header.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'dashboard-layout',
  standalone: true,
  imports: [DashHeaderComponent, RouterOutlet],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
})
export class DashboardLayoutComponent {}
