<div class="grid grid-cols-6 gap-4">
  <div class="col-span-6">
    <p class="font-bold">What's happening today?</p>
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Periods</p>
    @if (schoolTimeTableData.length>0) {
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
      {{schoolTodayStats.periods}}</p>
    }@else {
    <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">No timetable mapped</p>
    }
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Student Attendance</p>
    @if (schoolStudentsData.length>0) {
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
      {{schoolTodayStats.students_present}}/{{schoolStudentsData.length}}</p>
    }@else {
    <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">No Students</p>
    }
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Teachers Present</p>
    @if (schoolTeachersData.length>0) {
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
      {{schoolTodayStats.teachers_present}}/{{schoolTeachersData.length}}</p>
    }@else {
    <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">No Teachers</p>
    }
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Contents Casted</p>
    @if (allClassSyllabusArray.length>0) {
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
      {{schoolTodayStats.content_casted}}</p>
    }@else {
    <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">Syllabus not Mapped</p>
    }
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Topics Taught</p>
    @if (allClassSyllabusArray.length>0) {
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
      {{schoolTodayStats.topics_taught}}</p>
    }@else {
    <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">Syllabus not Mapped</p>
    }
  </div>
  <div class="col-span-3 md:col-span-2 school_today_card">
    <p>Assessments Taken</p>
    <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">{{schoolTodayStats.assessments_taken}}</p>
  </div>
</div>