<mat-tab-group #daysTab [mat-stretch-tabs]="true" [selectedIndex]="selectedIndex"
  (selectedIndexChange)="onTabSelected($event)">
  @for (day of weekDays; track $index) {
  <mat-tab [label]="day|titlecase">
    <div class="p-3">
      @if (selectedDayClassWisePeriods.length==0) {
      <div class="flex flex-col gap-1 justify-center items-center">
        <img src="../../../../assets/images/no_data.svg" alt="No Periods" width="300" height="300">
        <p>No Periods found</p>
      </div>
      }@else {
      <div class="flex md:justify-center md:items-center">
        <div class="flex flex-col gap-2 w-full md:w-auto">
          @for (period of selectedDayClassWisePeriods; track $index) {
          <div class="period_card_container flex items-center">
            <p class="period_index">P <sub>{{$index+1}}</sub></p>
            <div class="horizontal_line ml-1"></div>
            <div class="period_card flex-1">
              <div class="flex flex-col md:flex-row md:items-center md:justify-between">
                <div class="flex flex-col gap-1">
                  @if(period.type == 'assembly'|| period.type == 'lunch'
                  || period.type == 'co-curricular activities'|| period.type =='short break'){
                  <p class="text-hurrey-16  lg:text-hurrey-20 font-bold">{{period.type |
                    titlecase}}</p>
                  }@else {
                  @if(period.subject && period.teacher){

                  <p class="text-hurrey-16  lg:text-hurrey-20">{{period.subject|titlecase}}</p>
                  <p class="text-hurrey-12  lg:text-hurrey-16 flex items-center gap-1">
                    <span>
                      <mat-icon class="text-hurrey-12">person</mat-icon>
                    </span>
                    <span>
                      {{period.teacher|titlecase}}
                    </span>
                  </p>


                  }@else {
                  <p class="text-hurrey-12  lg:text-hurrey-26 italic">Leisure Period</p>

                  }

                  }



                </div>
                <p class="text-hurrey-12">{{period.start}} - {{period.end}}</p>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </mat-tab>
  }

</mat-tab-group>

<!-- () -->