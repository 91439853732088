<div class="grid grid-cols-12 gap-4 gap-y-6">
  @for (subjectStat of subjectWiseClassStats; track $index) {
  <div class="col-span-12 md:cols-span-6 lg:col-span-4 subject_card flex">
    <div class="w-1 bg-purple_100"></div>
    <div class="flex flex-col gap-3 px-3 py-2 flex-1">
      <div class="flex items-center justify-between">
        <p class="subject text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold w-full">
          {{subjectStat.subject|titlecase}}
        </p>
        @if (subjectStat.contentCasted>0) {
        <div class="flex gap-2 items-center content_casted">
          <mat-icon>cast_connected</mat-icon>
          <span class="text-hurrey-20">{{subjectStat.contentCasted}}</span>
        </div>
        }
      </div>
      <div class="flex items-center justify-between">
        <div>
          <p>Syllabus :
            @if (subjectStat.syllabusProgress>0) {
            <span>{{subjectStat.syllabusProgress}}%</span>
            }@else {
            <span>-</span>
            }
          </p>
        </div>
        <div class="w-[1px] h-4 bg-grey"></div>
        <div>
          <p>Performance :
            @if (subjectStat.performance>=0) {
            <span>{{subjectStat.performance}}%</span>
            }@else {
            <span>-</span>
            }
          </p>
        </div>
      </div>
      @if(subjectStat.teacher_name){
      <p class="flex items-center gap-1">
        <span class="text-hurrey-12">
          <mat-icon>person</mat-icon>
        </span>
        <span>
          {{subjectStat.teacher_name}}
        </span>
      </p>
      }@else {
      <p class="italic opacity-50">No teacher assigned</p>
      }

    </div>

  </div>
  }
</div>