export enum SCHOOL_RECOMMENDATIONS {
  CLASS_NOT_FOUND = '',
  TEACHER_NOT_FOUND = '',
  TEACHER_NOT_MAPPED_TO_CLASS = '',
  STUDENTS_NOT_FOUND = '',
  TIMETABLES_NOT_FOUND = '',
  TIMETABLE_NOT_MAPPED_TO_CLASS = '',
  TIMETABLE_NOT_MAPPED_TO_TEACHER = '',
  CLASS_PERFORMANCE_LOW = '',
  CLASS_ATTENDANCE_LOW = '',
  CLASS_ATTENDANCE_NOT_TAKEN = '',
  TEACHER_NOT_LOGGED_IN = '',
  TEACHER_NOT_LOGGED_IN_TODAY = '',
  CLASS_ASSESSMENTS_NOT_TAKEN = '',
  CLASS_HAS_NO_STUDENTS = '',
}
