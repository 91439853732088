@if(!loading){
<div class="grid grid-cols-3 h-full p-6">
  <div class="col-span-3 md:col-span-1">
    <img class="amns_logo" src="../../../assets/images/amns_logo.webp" alt="amns-logo">
  </div>
  <div class="col-span-3 md:col-span-1 flex justify-center items-center">

    <form autocomplete="off" class="flex flex-col gap-2 density-2xs" [formGroup]="signInForm">

      <div class="flex flex-col gap-6">
        <p class="text-hurrey-20 lg:text-hurrey-24">Welcome to Digital Pathshala</p>
        <p class="text-hurrey-16 md:text-hurrey-20 lg:text-hurrey-24 mb-6">Sign In</p>
      </div>



      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Enter your email</mat-label>
        <input matInput placeholder="superadmin@amns.com" formControlName="email" required />
        <mat-icon matSuffix>email</mat-icon>
        @if (email.invalid) {
        <mat-error>{{getEmailErrorMessage()}}</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        @if (password.invalid) {
        <mat-error>{{getPasswordErrorMessage()}}</mat-error>
        }
      </mat-form-field>

      <button [disabled]="signInForm.invalid" mat-flat-button color="primary" (click)="fetchFBUser()">Login</button>

      <a class="mt-3 cursor-pointer underline" href="/forgot-password">Forgot Password?</a>
    </form>

  </div>
  <div class="col-span-3 md:col-span-1 relative">
    <div class="absolute bottom-0 right-0 flex items-center gap-1 justify-end">
      <p class="powered_by">powered by</p>
      <img class="hurrey-logo" src="../../../assets/images/Hurrey logo.webp" alt="hurrey-logo">
    </div>
  </div>
</div>
}@else {
<div class="h-full flex justify-center items-center">
  <mat-spinner></mat-spinner>
</div>
}