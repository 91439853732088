import { Component, inject, Input } from '@angular/core';
import { SchoolClassData } from '../../../../../shared/models/school-class.model';
import { SchoolStudentDetailsModel } from '../../../../../shared/models/school-student.model';
import { SchoolTeacherDetailModel } from '../../../../../shared/models/school-teacher.model';
import { UtilsService } from '../../../../../services/utils.service';
import { SchoolStudentAssessmentModel } from '../../../../../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModel } from '../../../../../shared/models/school-student-attendance.model';
import { SchoolTeacherAttendanceModel } from '../../../../../shared/models/school-teacher-attendance.model';
import { SchoolClassSyllabusModel } from '../../../../../shared/models/school-class-syllabus.model';
import { SchoolTimetableWithDays } from '../../../../../shared/models/school-timetable.model';

@Component({
  selector: 'school-today',
  standalone: true,
  imports: [],
  templateUrl: './school-today.component.html',
  styleUrl: './school-today.component.scss',
})
export class SchoolTodayComponent {
  @Input() schoolClassData: SchoolClassData[] = [];
  @Input() schoolStudentsData: SchoolStudentDetailsModel[] = [];
  @Input() schoolTeachersData: SchoolTeacherDetailModel[] = [];
  @Input() schoolTimeTableData: SchoolTimetableWithDays[] = [];

  private utilSrvc_: UtilsService = inject(UtilsService);

  allClassSyllabusArray: SchoolClassSyllabusModel[] = [];

  schoolTodayStats: {
    students_present: number;
    teachers_present: number;
    content_casted: number;
    topics_taught: number;
    assessments_taken: number;
    periods: number;
  } = {
    students_present: 0,
    teachers_present: 0,
    content_casted: 0,
    topics_taught: 0,
    assessments_taken: 0,
    periods: 0,
  };

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    console.log(this.schoolClassData);

    this.populateData();
  }

  populateData() {
    let allSchoolAssessments = this.schoolClassData.reduce<
      SchoolStudentAssessmentModel[]
    >((acc, curr) => acc.concat(curr.class_assessments), []);

    let allSchoolAttendance = this.schoolClassData.reduce<
      SchoolStudentAttendanceModel[]
    >((acc, curr) => acc.concat(curr.class_attendance), []);

    let allSchoolTeacherAttendance = this.schoolTeachersData.reduce<
      SchoolTeacherAttendanceModel[]
    >(
      (acc, curr) =>
        acc.concat(curr.teacherAttendance as SchoolTeacherAttendanceModel[]),
      []
    );

    this.allClassSyllabusArray = this.schoolClassData.reduce<
      SchoolClassSyllabusModel[]
    >(
      (acc, curr) =>
        acc.concat(curr.class_syllabus as SchoolClassSyllabusModel[]),
      []
    );

    this.schoolTodayStats.students_present =
      this.utilSrvc_.getStrengthPresentToday(allSchoolAttendance);

    this.schoolTodayStats.teachers_present =
      this.utilSrvc_.getStrengthPresentToday(allSchoolTeacherAttendance);

    this.schoolTodayStats.assessments_taken =
      this.utilSrvc_.getTodayUniqueAssessmentCount(allSchoolAssessments);

    this.schoolTodayStats.content_casted =
      this.utilSrvc_.getContentsCastedToday(this.allClassSyllabusArray);

    this.schoolTodayStats.topics_taught =
      this.utilSrvc_.getTodayTaughtTopicsCount(this.allClassSyllabusArray);

    this.schoolTodayStats.periods = this.utilSrvc_.getTodayPeriods(
      this.schoolTimeTableData
    );

    console.log(this.schoolTodayStats);
  }
}
