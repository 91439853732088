import { Component, inject, Input } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { UtilsService } from '../../../../../services/utils.service';
import { SchoolClassSyllabusModel } from '../../../../../shared/models/school-class-syllabus.model';
import {
  SchoolClassModel,
  SubjectwiseClassStats,
} from '../../../../../shared/models/school-class.model';
import { SchoolStudentAssessmentModel } from '../../../../../shared/models/school-student-assessment.model';
import { SchoolTeacherDetailModel } from '../../../../../shared/models/school-teacher.model';

@Component({
  selector: 'class-subject-stats',
  standalone: true,
  imports: [TitleCasePipe, MatIconModule],
  templateUrl: './class-subject-stats.component.html',
  styleUrl: './class-subject-stats.component.scss',
})
export class ClassSubjectStatsComponent {
  @Input() classAssessments: SchoolStudentAssessmentModel[] = [];
  @Input() classSyllabusData: SchoolClassSyllabusModel[] = [];
  @Input() classDetails!: SchoolClassModel;
  @Input() classTeachers: SchoolTeacherDetailModel[] = [];

  subjectWiseClassStats: SubjectwiseClassStats[] = [];

  private utilsSrvc_: UtilsService = inject(UtilsService);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.populateSubjectWiseStats();
    console.log(this.subjectWiseClassStats);
  }

  populateSubjectWiseStats() {
    let subjects = this.classDetails.subjects;
    this.subjectWiseClassStats = subjects.map((subject: string) => {
      let subjectSyllabus = this.classSyllabusData.find(
        (syllabus) => syllabus.subject.toLowerCase() == subject.toLowerCase()
      );
      let subjectWiseStat: SubjectwiseClassStats = {
        subject: subject.toLowerCase(),
        contentCasted: !!subjectSyllabus
          ? this.utilsSrvc_.subjectWiseContentCasted(subjectSyllabus)
          : 0,
        performance: this.utilsSrvc_.calculatePerformance(
          this.classAssessments.filter(
            (assessment) =>
              assessment.subject.toLowerCase() == subject.toLowerCase()
          )
        ),
        syllabusProgress: !!subjectSyllabus
          ? this.utilsSrvc_.subjectSyllabusCompletion(subjectSyllabus)
          : -1,
        teacher_name: this.returnTeacherName(subject),
      };
      return subjectWiseStat;
    });
  }

  returnTeacherName(subject: string) {
    let classTeacherFound = this.classDetails.teachers.find(
      (teacher) => teacher.subject == subject
    );
    if (classTeacherFound) {
      let classTeacherDetails = this.classTeachers.find(
        (teacher) =>
          teacher.teacher_details.docId == classTeacherFound.teacher_id
      );
      if (classTeacherDetails) {
        return this.utilsSrvc_.toTitleCase(
          classTeacherDetails.teacher_details.name.join(' ')
        );
      }
    }
    return '';
  }
}
