import {
  computed,
  inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  Auth,
  authState,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
} from '@angular/fire/auth';
import {
  catchError,
  defer,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  SuperAdminModel,
  SuperAdminModelConverter,
} from '../shared/models/superadmin.model';
import {
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { UserModelConverter } from '../shared/models/user.model';

export type AuthUser = User | null | undefined;

interface AuthState {
  user: AuthUser;
  superAdmin: SuperAdminModel | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private fireAuth: Auth = inject(Auth);

  private user$: Observable<User | null> = authState(this.fireAuth);

  private state: WritableSignal<AuthState> = signal<AuthState>({
    user: undefined,
    superAdmin: undefined,
  });

  private fireStore: Firestore = inject(Firestore);

  private router: Router = inject(Router);

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  user: Signal<AuthUser> = computed(() => this.state().user);

  superAdmin: Signal<SuperAdminModel | undefined> = computed(
    () => this.state().superAdmin
  );

  private readonly inactivityTimeout = 30 * 60 * 1000; // 30 minutes
  private lastActivityTime: number = Date.now();

  constructor() {
    this.user$.pipe(takeUntilDestroyed()).subscribe({
      next: (user: User | null) => {
        this.state.update((state: AuthState) => ({ ...state, user }));
        if (this.user()) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          this.clearLocalStorage();
        }
      },
    });
    this.monitorInactivity();
  }

  signInWithEmailPassword(email: string, password: string) {
    return from(
      defer(() => signInWithEmailAndPassword(this.fireAuth, email, password))
    ).pipe(switchMap(() => this.checkAndStoreSuperAdminData()));
  }

  fetchSuperAdminUser(uid: string) {
    let superAdminDocRef = doc(
      this.fireStore,
      `super_admin/${uid}`
    ).withConverter(SuperAdminModelConverter);
    return from(getDoc(superAdminDocRef)).pipe(
      map((superAdminDoc: DocumentSnapshot) => {
        if (superAdminDoc.exists()) {
          const superAdminData = superAdminDoc.data() as SuperAdminModel;
          if (superAdminData.access.every((access) => !access.active)) {
            this.logOut();
          } else {
            this.fetchUserDoc(uid)
              .pipe(
                catchError((error) => {
                  this.snackbarSrvc_.openErrorSnackbar(
                    'Error while processing the request'
                  );
                  this.logOut();
                  return of();
                })
              )
              .subscribe({
                next: (userDoc: DocumentSnapshot) => {
                  if (userDoc.exists()) {
                    localStorage.setItem(
                      'superAdminUserDoc',
                      JSON.stringify(userDoc.data())
                    );
                    this.state.update((state: AuthState) => ({
                      ...state,
                      superAdmin: superAdminData,
                    }));
                    localStorage.setItem(
                      'superAdmin',
                      JSON.stringify(superAdminData)
                    );
                    this.snackbarSrvc_.openSuccessSnackbar(
                      'Logged in Successfully'
                    );
                    this.router.navigate(['dashboard']);
                  } else {
                    this.snackbarSrvc_.openErrorSnackbar(
                      'Error while processing the request'
                    );
                    this.logOut();
                  }
                },
              });
          }
        } else {
          this.snackbarSrvc_.openErrorSnackbar(
            'Not a Superadmin.Please contact support team'
          );
          this.clearAllExceptOneKey('amns-theme');
        }
      }),
      catchError((error) => {
        this.snackbarSrvc_.openErrorSnackbar(
          'Error while processing the request'
        );
        this.logOut();
        return of();
      })
    );
  }

  checkAndStoreSuperAdminData(): Observable<void> {
    return this.user$.pipe(
      switchMap((user: User | null) => {
        if (!!user) {
          return this.fetchSuperAdminUser(user.uid);
        } else {
          this.logOut();
          return from([undefined]);
        }
      })
    );
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.fireAuth, email);
  }

  logOut() {
    this.clearLocalStorage();
    signOut(this.fireAuth)
      .then(() => {
        if (!this.router.url.includes('sign-in')) {
          this.snackbarSrvc_.openSuccessSnackbar('Logged out successfully!');
        }
        this.router.navigate(['sign-in']);
      })
      .catch((err) => {
        console.log(err, 'logged out');
        this.snackbarSrvc_.openErrorSnackbar(
          'Error occured while processing the request'
        );
      });
  }

  private clearLocalStorage() {
    // localStorage.removeItem('user');
    // localStorage.removeItem('superAdmin');
    this.clearAllExceptOneKey('amns-hurrey');
    this.state.update(() => ({
      user: undefined,
      superAdmin: undefined,
    }));
  }

  clearAllExceptOneKey(keyToKeep: string) {
    // Check if the key exists in localStorage
    const valueToKeep = localStorage.getItem(keyToKeep);

    // Clear all localStorage
    localStorage.clear();

    // Restore the key if it exists
    if (valueToKeep !== null) {
      localStorage.setItem(keyToKeep, valueToKeep);
    }
  }

  monitorInactivity() {
    window.addEventListener('mousemove', () => {
      this.resetInactivityTimer();
    });
    window.addEventListener('keydown', () => {
      this.resetInactivityTimer();
    });
    window.addEventListener('scroll', () => {
      this.resetInactivityTimer();
    });

    setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - this.lastActivityTime > this.inactivityTimeout) {
        this.logOut();
      }
    }, 60000); // Check every minute
  }

  private resetInactivityTimer() {
    this.lastActivityTime = Date.now();
  }

  fetchUserDoc(uid: string) {
    const userDocRef = doc(this.fireStore, `users/${uid}`).withConverter(
      UserModelConverter
    );

    return from(getDoc(userDocRef)).pipe(take(1));
  }
}
