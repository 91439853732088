import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'more-less',
  standalone: true,
  imports: [NgIf],
  templateUrl: './more-less.component.html',
  styleUrl: './more-less.component.scss',
})
export class MoreLessComponent {
  @Input() text: string = '';

  @Input() wordLimit: number = 0;

  showMore: boolean = false;
}
