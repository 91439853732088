import { Component, inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SchoolModel } from '../../../shared/models/school.model';
import { LocalStorageService } from '../../../services/local-storage.service';
import { JsonPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { SchoolService } from '../../../services/school.service';
import { MatCardModule } from '@angular/material/card';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../shared/decorators/unsubscribe.decorator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  SchoolStudentDetailsModel,
  SchoolStudentModel,
} from '../../../shared/models/school-student.model';
import { SchoolClassData } from '../../../shared/models/school-class.model';
import { StudentListComponent } from '../inner-pages/school-db/student-list/student-list.component';
import { SchoolStudentAssessmentModel } from '../../../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModel } from '../../../shared/models/school-student-attendance.model';
import { SchoolTeacherDetailModel } from '../../../shared/models/school-teacher.model';
import { SchoolTimetableWithDays } from '../../../shared/models/school-timetable.model';
import { SchoolNotificationService } from '../../../services/school-notification.service';
import { SchoolNotification } from '../../../shared/models/school-notifications.model';
import { MoreLessComponent } from '../../../shared/reusable-components/more-less/more-less.component';
import { MatIconModule } from '@angular/material/icon';
import { SchoolClassVisualsComponent } from '../inner-pages/school-db/school-class-visuals/school-class-visuals.component';
import { SchoolTodayComponent } from '../inner-pages/school-db/school-today/school-today.component';
import { ClassListComponent } from '../inner-pages/school-db/class-list/class-list.component';
import { TeacherListComponent } from '../inner-pages/school-db/teacher-list/teacher-list.component';

@Component({
  selector: 'school-dashboard',
  standalone: true,
  imports: [
    JsonPipe,
    MatCardModule,
    TitleCasePipe,
    UpperCasePipe,
    MatProgressSpinnerModule,
    ClassListComponent,
    StudentListComponent,
    TeacherListComponent,
    MoreLessComponent,
    MatIconModule,
    SchoolClassVisualsComponent,
    SchoolTodayComponent,
  ],
  templateUrl: './school-dashboard.component.html',
  styleUrl: './school-dashboard.component.scss',
  providers: [TitleCasePipe],
})
@unsubscribe()
export class SchoolDashboardComponent implements OnDestroy {
  private activatedRoute_: ActivatedRoute = inject(ActivatedRoute);
  private localStorageSrvc_: LocalStorageService = inject(LocalStorageService);

  private schoolSrvc_: SchoolService = inject(SchoolService);

  private titleCasePipe: TitleCasePipe = inject(TitleCasePipe);

  private schoolNotSrvc_: SchoolNotificationService = inject(
    SchoolNotificationService
  );

  schoolStudents: SchoolStudentModel[] = [];

  schoolClassData: SchoolClassData[] = [];

  schoolStudentData: SchoolStudentDetailsModel[] = [];

  dataLoaded!: boolean;

  studentSubscription$!: Subscription;

  teacherSubscription$!: Subscription;

  classSubscription$!: Subscription;

  schoolTimetableSub$!: Subscription;

  schoolDetails!: SchoolModel;

  schoolAssessments: SchoolStudentAssessmentModel[] = [];

  schoolAttendanceDocs: SchoolStudentAttendanceModel[] = [];

  schoolTeacherData: SchoolTeacherDetailModel[] = [];

  schoolTimetableData: SchoolTimetableWithDays[] = [];

  schoolRecommendations: SchoolNotification[] = [];

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.localStorageSrvc_.removeItem('selected_class');
    this.localStorageSrvc_.removeItem('school_curriculum');
    this.schoolDetails = (
      this.localStorageSrvc_.getItem('superAdminSchools') as SchoolModel[]
    ).find(
      (school) =>
        school.docId == atob(this.activatedRoute_.snapshot.params['school_id'])
    ) as SchoolModel;

    console.log(this.schoolDetails);

    this.dataLoaded = false;

    this.studentSubscription$ = this.schoolSrvc_
      .fetchStudents(this.schoolDetails.docId!)
      .subscribe({
        next: (el) => {
          this.schoolStudents = el.map((a) => a.student_doc);

          this.schoolStudentData = el;

          this.teacherSubscription$ = this.schoolSrvc_
            .fetchTeachers(this.schoolDetails.docId!)
            .subscribe({
              next: (el) => {
                console.log(el);
                this.schoolTeacherData = el;

                this.classSubscription$ = this.schoolSrvc_
                  .fetchClasses(
                    this.schoolDetails.docId!,
                    this.schoolDetails.curriculum
                  )
                  .subscribe({
                    next: (el) => {
                      this.schoolClassData = el;

                      if (this.schoolClassData.length) {
                        this.schoolAssessments = this.schoolClassData.reduce(
                          (acc, curr) => acc.concat(curr.class_assessments),
                          [] as SchoolStudentAssessmentModel[]
                        );
                        this.schoolAttendanceDocs = this.schoolClassData.reduce(
                          (acc, curr) => acc.concat(curr.class_attendance),
                          [] as SchoolStudentAttendanceModel[]
                        );
                      }
                      this.schoolTimetableSub$ = this.schoolSrvc_
                        .fetchTimeTablesAlongWithDays(
                          this.schoolDetails.docId!,
                          this.schoolDetails.curriculum
                        )
                        .subscribe({
                          next: (val) => {
                            this.dataLoaded = true;
                            this.schoolTimetableData = val;

                            this.schoolRecommendations =
                              this.schoolNotSrvc_.getNotifications(
                                this.schoolClassData,
                                this.schoolStudentData,
                                this.schoolTeacherData,
                                this.schoolTimetableData
                              );
                          },
                          error: (err) => {
                            this.dataLoaded = false;
                            this.snackbarSrvc_.openErrorSnackbar(
                              'Error while processing the request'
                            );
                            console.log(err);
                          },
                        });
                    },
                    error: () => {
                      this.dataLoaded = true;
                      this.snackbarSrvc_.openErrorSnackbar(
                        'Error while processing the request'
                      );
                    },
                  });
              },
              error: () => {
                this.dataLoaded = true;
                this.snackbarSrvc_.openErrorSnackbar(
                  'Error while processing the request'
                );
              },
            });
        },
        error: () => {
          this.dataLoaded = true;
          this.snackbarSrvc_.openErrorSnackbar(
            'Error while processing the request'
          );
        },
      });
  }

  schoolAddress() {
    let city = this.titleCasePipe.transform(this.schoolDetails.address.city),
      district = this.titleCasePipe.transform(
        this.schoolDetails.address.district
      ),
      state = this.titleCasePipe.transform(this.schoolDetails.address.state);

    if (city && district && state && city != district) {
      return `${city}, ${district}, ${state} - ${this.schoolDetails.address.pincode}`;
    } else if (district && state) {
      return `${district}, ${state} - ${this.schoolDetails.address.pincode}`;
    } else {
      return `${state} - ${this.schoolDetails.address.pincode}`;
    }
  }

  returnPhoneNumber(input: string): { telLink: string; phone: number } | null {
    const digitsOnly = input.replace(/\D/g, '');

    if (digitsOnly.startsWith('91') && digitsOnly.length === 12) {
      return {
        telLink: `tel:+91${digitsOnly.slice(2)}`,
        phone: +digitsOnly.slice(2),
      };
    } else if (digitsOnly.length === 10) {
      return { telLink: `tel:+91${digitsOnly}`, phone: +digitsOnly };
    } else {
      return null;
    }
  }

  returnEmail(email: string) {
    return `mailto:${email}`;
  }

  ngOnDestroy(): void {}
}
