<div class="flex flex-col gap-3">
  <div class="flex items-center justify-between">
    <p class="font-bold">Students</p>
    <mat-form-field appearance="outline">
      <mat-label>Classes</mat-label>
      <mat-select [formControl]="selectedClassesFormControl" multiple (selectionChange)="filterTable()">
        <mat-select-trigger>
          {{(selectedClassesFormControl.value?.[0] |titlecase) || ''}}
          <span
            *ngIf="(selectedClassesFormControl.value?.length || 0) > 1 && (selectedClassesFormControl.value?.length || 0) < classes.length "
            class="example-additional-selection">
            (+{{(selectedClassesFormControl.value?.length || 0) - 1}} {{selectedClassesFormControl.value?.length === 2 ?
            'other' : 'others'}})
          </span>
          <span *ngIf="((selectedClassesFormControl.value?.length-1 || 0) == classes.length)"
            class="example-additional-selection">
            All Classes
          </span>

        </mat-select-trigger>

        <mat-option selectAll [allValues]="classes">Select all</mat-option>

        <mat-option *ngFor="let class of classes" [value]="class">{{class|titlecase}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8 table_container">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortTableData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Class </th>
        <td mat-cell *matCellDef="let row"> {{row.class|titlecase}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="roll">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Roll No </th>
        <td mat-cell *matCellDef="let row"> {{row.roll}} </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="performance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Performance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.performance>=0){
          <span>{{row.performance}}%</span>
          }@else {
          <span>No performance data</span>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.attendance>=0){
          <span>{{row.attendance}}%</span>
          }@else {
          <span>No attendance data</span>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="today_attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Today's Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.present!=-1){
          @if (row.present) {
          <span class="text-amber_100">Present</span>
          }@else {
          <span class="text-rose_100">Absent</span>
          }
          }@else {
          <span>Not marked</span>
          }
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of students"></mat-paginator>
  </div>

</div>