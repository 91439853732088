@if (classDataLoaded) {
<div class="flex flex-col gap-6 p-4 md:p-6 lg:p-8">
  <div class="class_title flex items-center gap-2 cursor-pointer">
    <h1>Class {{classDetails.name}} {{classDetails.section|uppercase}}</h1>
  </div>

  <div class="grid grid-cols-12 gap-3">
    <div class="class_db_card col-span-6 lg:col-span-3">
      <p>Students</p>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">{{classStudents.length}}</p>
    </div>
    <div class="class_db_card col-span-6 lg:col-span-3">
      <p>Attendance</p>
      @if (classAttendancePercentage>=0) {
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
        {{classAttendancePercentage}}%</p>
      }@else {
      <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">Attendance not taken</p>
      }
    </div>
    <div class="class_db_card col-span-6 lg:col-span-3">
      <p>Performance</p>
      @if (classPerformance>=0) {
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
        {{classPerformance}}%</p>
      }@else {
      <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">Assessment not taken</p>
      }
    </div>
    <div class="class_db_card col-span-6 lg:col-span-3">
      <p>Class Teacher</p>
      @if (classTeacherName) {
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28 font-bold">
        <span>
          <mat-icon>person</mat-icon>
        </span>
        <span>
          {{classTeacherName}}
        </span>
      </p>
      }@else {
      <p class="text-hurrey-12 lg:text-hurrey-16 italic opacity-50">Not Assigned</p>
      }
    </div>
  </div>

  <div>
    <h3>Subject Wise Stats</h3>
    @if (classDetails.subjects.length) {
    <class-subject-stats [classAssessments]="classAssessments" [classDetails]="classDetails"
      [classSyllabusData]="classSyllabusData" [classTeachers]="classTeachers"></class-subject-stats>
    }@else{
    <div class="flex flex-col justify-center items-center">
      <img src="../../../assets/images/no_data.svg" alt="No subjects mapped to class" width="300" height="300">
      <p>No subjects mapped to class</p>
    </div>
    }
  </div>

  <div>
    <h3>Class Students</h3>
    @if (classStudents.length>0) {
    <class-students-list [classAssessments]="classAssessments" [classStudents]="classStudents"
      [classAttendance]="classAttendance"></class-students-list>
    }@else{
    <div class="flex flex-col justify-center items-center">
      <img src="../../../assets/images/no_data.svg" alt="No Students" width="300" height="300">
      <p>No Students Found</p>
    </div>
    }
  </div>

  <div>
    <h3>Class Timetable</h3>
    @if (!!classTimetable) {
    <class-timetable [classTimetableData]="classTimetable" [classTeachers]="classTeachers"
      [class_id]="class_id"></class-timetable>
    }@else {
    <div class="flex flex-col justify-center items-center">
      <img src="../../../assets/images/no_data.svg" alt="Timetable not found" width="300" height="300">
      <p>Timetable not created</p>
    </div>
    }
  </div>

</div>
}@else {
<div class="h-full w-full flex justify-center items-center">
  <mat-spinner color="primary"></mat-spinner>
</div>
}