<div class="flex flex-col gap-3">
  <p class="font-bold">Classes</p>

  <div class="mat-elevation-z8 table_container">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortTableData($event)">

      <!-- ID Column -->
      <ng-container matColumnDef="class" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Class </th>
        <td mat-cell *matCellDef="let row"> {{row.class_name|titlecase}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="students">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Students </th>
        <td mat-cell *matCellDef="let row"> {{row.students}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="performance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Performance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.performance>=0){
          <span>{{row.performance}}%</span>
          }@else {
          <span>No performance data</span>
          }
        </td>

      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.attendance>=0){
          <span>{{row.attendance}}%</span>
          }@else {
          <span>No attendance data</span>
          }
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="today_attendance">
        <th mat-header-cell *matHeaderCellDef> Today's Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.attendance>=0){
          <span>{{row.today_attendance}}/{{row.students}}</span>
          }@else {
          <span>Not marked</span>
          }
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="view_class">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button (click)="navigateToClassDashboard(row.class_ref)" type="button" class="density-2xs"
            mat-icon-button><mat-icon>arrow_forward</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr> -->
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of classes"></mat-paginator>
  </div>

</div>