import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  DocumentData,
  Firestore,
  Query,
  query,
  where,
} from '@angular/fire/firestore';
import { SuperAdminAccessModel } from '../shared/models/superadmin.model';
import { SchoolModelConverter } from '../shared/models/school.model';
import { forkJoin, Observable, take } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private fireStore: Firestore = inject(Firestore);
  constructor() {}

  fetchSuperAdminSchools(
    activeAccess: SuperAdminAccessModel[]
  ): Observable<any[]> {
    let array: Observable<any[]>[] = [];
    for (const access of activeAccess) {
      let schoolsQuery: Query<DocumentData, DocumentData> | null = null;
      if (
        access.city.length > 0 &&
        access.district.length > 0 &&
        access.state.length > 0
      ) {
        if (!environment.production) {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where(
              'address.district',
              '==',
              access.district.toLowerCase().trim()
            ),
            where('address.city', '==', access.city.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim())
            // where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        } else {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where(
              'address.district',
              '==',
              access.district.toLowerCase().trim()
            ),
            where('address.city', '==', access.city.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim()),
            where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        }
      } else if (access.district.length > 0 && access.state.length > 0) {
        if (!environment.production) {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where(
              'address.district',
              '==',
              access.district.toLowerCase().trim()
            ),
            where('school_type', '==', access.school_type.toLowerCase().trim())
            // where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        } else {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where(
              'address.district',
              '==',
              access.district.toLowerCase().trim()
            ),
            where('school_type', '==', access.school_type.toLowerCase().trim()),
            where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        }
      } else if (access.state.length > 0) {
        if (!environment.production) {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim())
            // where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        } else {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('address.state', '==', access.state.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim()),
            where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        }
      } else {
        if (!environment.production) {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim())
            // where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        } else {
          schoolsQuery = query(
            collection(this.fireStore, `schools`).withConverter(
              SchoolModelConverter
            ),
            where('address.country', '==', access.country.toLowerCase().trim()),
            where('school_type', '==', access.school_type.toLowerCase().trim()),
            where('vendor', '==', access.vendor?.toLowerCase().trim())
          );
        }
      }
      if (schoolsQuery) {
        const collectionObs = collectionData(schoolsQuery).pipe(take(1));
        // Ensure it completes after one emission
        array.push(collectionObs);
      }
    }
    return forkJoin(array).pipe();
  }
}
