import {
  SchoolTeacherModel,
  SchoolTeacherClassModel,
  SchoolTeacherDetailModel,
} from './../shared/models/school-teacher.model';
import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import {
  catchError,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { SchoolStudentAssessmentModelConverter } from '../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModelConverter } from '../shared/models/school-student-attendance.model';
import {
  SchoolStudentDetailsModel,
  SchoolStudentModel,
  SchoolStudentModelConverter,
} from '../shared/models/school-student.model';
import { SchoolModelCurriculum } from '../shared/models/school.model';
import {
  SchoolTimetableDaysModel,
  SchoolTimetableDaysModelConverter,
  SchoolTimetableModel,
  SchoolTimetableModelConverter,
  SchoolTimetableWithDays,
} from '../shared/models/school-timetable.model';
import { SchoolTeacherModelConverter } from '../shared/models/school-teacher.model';
import { UserModel, UserModelConverter } from '../shared/models/user.model';
import { SchoolTeacherAttendanceModel } from '../shared/models/school-teacher-attendance.model';
import { SchoolClassSyllabusModelConverter } from '../shared/models/school-class-syllabus.model';

@Injectable({
  providedIn: 'root',
})
export class SchoolClassService {
  private firestore: Firestore = inject(Firestore);

  constructor() {}

  fetchClassStudents(school_id: string, class_doc_id: string) {
    let class_ref = doc(
      this.firestore,
      `schools/${school_id}/classes/${class_doc_id}`
    );

    console.log(class_ref);
    let classStudentsQuery = query(
      collection(this.firestore, 'school_student').withConverter(
        SchoolStudentModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('class_ref', '==', class_ref)
    );

    let classStudents$ = collectionData(classStudentsQuery).pipe(
      take(1),
      switchMap((students: SchoolStudentModel[]) => {
        if (students.length) {
          return forkJoin(
            students.map((student: SchoolStudentModel) =>
              this.fetchUserDetails(student.user_id).pipe(
                map((userDoc) => {
                  if (userDoc.exists()) {
                    const userDetails = userDoc.data() as UserModel;
                    return {
                      student_doc: student,
                      student_details: userDetails,
                    } as SchoolStudentDetailsModel;
                  } else {
                    return {
                      student_doc: student,
                      student_details: {},
                    } as SchoolStudentDetailsModel;
                  }
                })
              )
            )
          );
        } else {
          return of([] as SchoolStudentDetailsModel[]);
        }
      })
    );
    let classAttendance$ = this.fetchClassAttendance(class_ref);
    let classAssessments$ = this.fetchClassAssessments(class_ref);

    return forkJoin({
      class_students: classStudents$,
      class_attendance: classAttendance$,
      class_assessments: classAssessments$,
    });
  }

  fetchClassTeachers(school_id: string, class_doc_id: string) {
    let teacherQuery = query(
      collection(this.firestore, 'school_teacher').withConverter(
        SchoolTeacherModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_id)
    );

    let schoolteachers$ = collectionData(teacherQuery);

    return schoolteachers$.pipe(
      switchMap((teachers: SchoolTeacherModel[]) => {
        const filteredTeachers = teachers.filter(
          (teacher: SchoolTeacherModel) =>
            teacher.classes.some(
              (teacherClass: SchoolTeacherClassModel) =>
                teacherClass.class_doc_id === class_doc_id
            )
        );

        // Debugging: Check the filtered teachers

        if (filteredTeachers.length > 0) {
          const userDetailRequests: Observable<SchoolTeacherDetailModel>[] =
            filteredTeachers.map((teacher: SchoolTeacherModel) => {
              if (!teacher.user_id) {
                // Handle case where user_id is missing
                return of({
                  teacher_details: {}, // or some default structure
                  teacher_doc: teacher,
                } as SchoolTeacherDetailModel);
              }

              return this.fetchUserDetails(teacher.user_id).pipe(
                map((userDoc) => {
                  const userDetails = userDoc.exists()
                    ? (userDoc.data() as UserModel)
                    : ({} as UserModel);

                  return {
                    teacher_details: userDetails,
                    teacher_doc: teacher,
                  } as SchoolTeacherDetailModel;
                }),
                catchError((error) => {
                  return of({
                    teacher_details: {},
                    teacher_doc: teacher,
                  } as SchoolTeacherDetailModel); // Fallback on error
                })
              );
            });

          // Debugging: Check the userDetailRequests before forkJoin

          return forkJoin(userDetailRequests).pipe(
            catchError((error) => {
              return of([]); // Fallback in case of forkJoin error
            })
          );
        } else {
          return of([] as SchoolTeacherDetailModel[]);
        }
      })
    );
  }

  fetchClassAttendance(class_ref: DocumentReference) {
    let classAttendanceQuery = query(
      collection(this.firestore, 'school_student_attendance').withConverter(
        SchoolStudentAttendanceModelConverter
      ),
      where('class_ref', '==', class_ref)
    );
    return collectionData(classAttendanceQuery).pipe(take(1));
  }

  fetchClassAssessments(class_ref: DocumentReference) {
    let classAssessmentsQuery = query(
      collection(this.firestore, 'school_student_assessment').withConverter(
        SchoolStudentAssessmentModelConverter
      ),
      where('class_ref', '==', class_ref)
    );

    return collectionData(classAssessmentsQuery).pipe(take(1));
  }

  fetchClassTimetableWithDays(
    school_id: string,
    class_doc_id: string,
    curriculum: SchoolModelCurriculum
  ) {
    let classTimetableQuery = query(
      collection(
        this.firestore,
        `schools/${school_id}/timetable`
      ).withConverter(SchoolTimetableModelConverter),
      where('curriculum.end_month', '==', curriculum.end_month),
      where('curriculum.start_month', '==', curriculum.start_month),
      where('curriculum.start_year', '==', curriculum.start_year),
      where('curriculum.end_year', '==', curriculum.end_year),
      where('class_doc_ids', 'array-contains', class_doc_id)
    );

    let classTimetable$ = collectionData(classTimetableQuery);
    return classTimetable$.pipe(
      switchMap((timetables: SchoolTimetableModel[]) => {
        if (!timetables || timetables.length === 0) {
          // If no timetables exist, return an empty array or handle it as needed
          return of([] as SchoolTimetableWithDays[]);
        }
        const timetablesWithDays$ = timetables.map((timetable) =>
          this.fetchTimetableDays(school_id, timetable.docId!).pipe(
            map((days: { [key: string]: SchoolTimetableDaysModel }) => {
              return {
                timetable: timetable,
                days: days,
              } as SchoolTimetableWithDays;
            })
          )
        );

        return forkJoin(timetablesWithDays$);
      })
    );
  }

  fetchTimetableDays(school_id: string, timetable_doc_id: string) {
    let timetableDaysQuery = query(
      collection(
        this.firestore,
        `schools/${school_id}/timetable/${timetable_doc_id}/days`
      ).withConverter(SchoolTimetableDaysModelConverter)
    );

    return collectionData(timetableDaysQuery).pipe(
      take(1),
      map((days: SchoolTimetableDaysModel[]) => {
        const daysObject: { [key: string]: SchoolTimetableDaysModel } = {};
        days.forEach((day: SchoolTimetableDaysModel) => {
          daysObject[day.docId!] = day;
          delete day.docId;
        });
        return daysObject;
      })
    );
  }

  fetchUserDetails(doc_id: string) {
    let userDocRef = doc(this.firestore, `users/${doc_id}`).withConverter(
      UserModelConverter
    );

    return from(getDoc(userDocRef)).pipe(take(1));
  }

  fetchClassSyllabusDocs(school_id: string, class_doc_id: string) {
    let schoolClassSyllabusQuery = query(
      collection(
        this.firestore,
        `schools/${school_id}/classes/${class_doc_id}/syllabus`
      ).withConverter(SchoolClassSyllabusModelConverter)
    );

    return collectionData(schoolClassSyllabusQuery);
  }
}
