import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  SuperAdminAccessModel,
  SuperAdminModel,
} from '../../../shared/models/superadmin.model';
import { CommonService } from '../../../services/common.service';
import { SchoolModel } from '../../../shared/models/school.model';
import { UtilsService } from '../../../services/utils.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../shared/decorators/unsubscribe.decorator';
import { UserModel } from '../../../shared/models/user.model';
import { TitleCasePipe } from '@angular/common';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SuperadminAdminsListComponent } from '../inner-pages/super-admin/superadmin-admins-list/superadmin-admins-list.component';
import { SuperadminCounterComponent } from '../inner-pages/super-admin/superadmin-counter/superadmin-counter.component';
import { SuperadminSchoolsListComponent } from '../inner-pages/super-admin/superadmin-schools-list/superadmin-schools-list.component';
import { SuperadminSchoolsVisualsComponent } from '../inner-pages/super-admin/superadmin-schools-visuals/superadmin-schools-visuals.component';
import { SuperadminStudentsListComponent } from '../inner-pages/super-admin/superadmin-students-list/superadmin-students-list.component';
import { SuperadminTeachersListComponent } from '../inner-pages/super-admin/superadmin-teachers-list/superadmin-teachers-list.component';
import { SuperadminService } from '../../../services/superadmin.service';
import { SuperAdminCastingVisualsComponent } from '../inner-pages/super-admin/super-admin-casting-visuals/super-admin-casting-visuals.component';

@Component({
  selector: 'dashboard',
  standalone: true,
  imports: [
    MatButtonModule,
    SuperadminCounterComponent,
    MatProgressSpinnerModule,
    SuperadminSchoolsListComponent,
    SuperadminStudentsListComponent,
    SuperadminTeachersListComponent,
    SuperadminAdminsListComponent,
    SuperadminSchoolsVisualsComponent,
    TitleCasePipe,
    SuperAdminCastingVisualsComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
@unsubscribe()
export class DashboardComponent implements OnInit, OnDestroy {
  private commonSrvc_: CommonService = inject(CommonService);

  castedHours!:number

  private utilSrvc_: UtilsService = inject(UtilsService);

  private localStorageSrvc_: LocalStorageService = inject(LocalStorageService);

  private router: Router = inject(Router);

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  superAdminSchoolSubscription$!: Subscription;

  schoolsDataLoaded: boolean = false;

  superAdminDoc: SuperAdminModel = JSON.parse(
    localStorage.getItem('superAdmin')!
  );

  superAdminUserDoc: UserModel = JSON.parse(
    localStorage.getItem('superAdminUserDoc')!
  );

  activeAccessArray: SuperAdminAccessModel[] = [];

  superAdminSchools: SchoolModel[] = [];

  private superAdminSrvc_: SuperadminService = inject(SuperadminService);

  ngOnInit(): void {
    this.activeAccessArray = this.superAdminDoc.access.filter(
      (access: SuperAdminAccessModel) => !!access.active
    );

    this.schoolsDataLoaded = false;
    this.superAdminSchoolSubscription$ = this.commonSrvc_
      .fetchSuperAdminSchools(this.activeAccessArray)
      .subscribe({
        next: (results) => {
          this.schoolsDataLoaded = true;
          let schoolsData = results.flat();
          this.superAdminSchools = this.utilSrvc_.removeDuplicates(
            schoolsData as SchoolModel[],
            'docId'
          );
          this.localStorageSrvc_.setItem(
            'superAdminSchools',
            this.superAdminSchools
          );
          this.localStorageSrvc_.removeItem('selected_school_id');
          this.localStorageSrvc_.removeItem('selected_class');
          this.localStorageSrvc_.removeItem('school_curriculum');
        },
        error: (err) => {
          this.schoolsDataLoaded = true;
          this.snackbarSrvc_.openErrorSnackbar(
            'Error while processing the request'
          );
          console.error('Error fetching schools data:', err);
        },
        complete: () => {
          this.schoolsDataLoaded = true;
          console.log('School data fetching completed!');
        },
      });
  }

  returnSalutation() {
    return `${this.utilSrvc_.getSalutation()}`;
  }

  navigateToSchoolDashboard(school_doc_id: string) {
    this.localStorageSrvc_.setItem('selected_school_id', school_doc_id);
    this.router.navigate([`/dashboard/school/${btoa(school_doc_id)}`]);
  }

  isObjectEmpty(obj: any) {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  getCastedHours(hrs:number){
    this.castedHours = hrs
  }

  ngOnDestroy(): void {}
}
