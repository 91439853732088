import { Routes } from '@angular/router';
import { SignInComponent } from '../modules/auth/sign-in/sign-in.component';
import { DashboardComponent } from '../modules/dashboards/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../modules/auth/forgot-password/forgot-password.component';
import { authGuard } from '../shared/guards/auth.guard';
import { DashboardLayoutComponent } from '../modules/dashboards/dashboard-layout/dashboard-layout.component';
import { SchoolDashboardComponent } from '../modules/dashboards/school-dashboard/school-dashboard.component';
import { ClassDashboardComponent } from '../modules/dashboards/class-dashboard/class-dashboard.component';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
  {
    path: 'sign-in',
    component: SignInComponent,
    title: 'Sign In',
    // canActivate: [authGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    title: 'Forgot Password',
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    title: 'Dashboard',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        title: 'Superadmin Dashboard',
      },
      {
        path: 'school/:school_id',
        component: SchoolDashboardComponent,
        title: 'School Dashboard',
      },
      {
        path: 'school/:school_id/class/:class_id',
        component: ClassDashboardComponent,
        title: 'Class Dashboard',
      },
    ],
  },
];
