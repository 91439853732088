import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { DocumentReference } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../../../../../services/local-storage.service';
import { UtilsService } from '../../../../../services/utils.service';
import { unsubscribe } from '../../../../../shared/decorators/unsubscribe.decorator';
import {
  SchoolClassData,
  SchoolClassTable,
} from '../../../../../shared/models/school-class.model';
import { SchoolStudentModel } from '../../../../../shared/models/school-student.model';
import { SchoolModel } from '../../../../../shared/models/school.model';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'class-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    TitleCasePipe,
    MatIconModule,
  ],
  templateUrl: './class-list.component.html',
  styleUrl: './class-list.component.scss',
})
@unsubscribe()
export class ClassListComponent implements OnInit, OnDestroy {
  @Input() schoolClassesData: SchoolClassData[] = [];

  @Input() schoolStudents: SchoolStudentModel[] = [];

  @Input() schoolDetails!: SchoolModel;

  private utilSrvc_: UtilsService = inject(UtilsService);

  sortSubscription$!: Subscription;

  private localStorageSrvc_: LocalStorageService = inject(LocalStorageService);

  schoolClassesTableData: SchoolClassTable[] = [];

  private router: Router = inject(Router);

  displayedColumns: string[] = [
    'class',
    'students',
    'performance',
    'attendance',
    'today_attendance',
    'view_class',
  ];

  dataSource!: MatTableDataSource<SchoolClassTable>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sortSubscription$ = this.sort.sortChange.subscribe((el) => {
      this.paginator.firstPage();
    });
  }

  ngOnInit(): void {
    this.populatingClassesTable();
  }

  populatingClassesTable() {
    for (let schoolClass of this.schoolClassesData) {
      let rowTable: SchoolClassTable = {
        class_name: `${schoolClass.class_details.name} ${schoolClass.class_details.section}`,
        attendance: this.utilSrvc_.calulateAttendance(
          schoolClass.class_attendance
        ),
        performance: this.utilSrvc_.calculatePerformance(
          schoolClass.class_assessments
        ),
        students: this.studentsCount(schoolClass.class_details.docRef!),
        today_attendance: this.utilSrvc_.getStrengthPresentToday(
          schoolClass.class_attendance
        ),
        class_ref: schoolClass.class_details.docRef!,
      };
      this.schoolClassesTableData.push(rowTable);
    }

    this.schoolClassesTableData.sort((a, b) =>
      this.customClassSort(a.class_name, b.class_name, 'asc')
    );

    this.dataSource = new MatTableDataSource(this.schoolClassesTableData);
  }

  studentsCount(class_ref: DocumentReference) {
    if (this.schoolStudents.length) {
      return this.schoolStudents.filter(
        (schoolStudent: SchoolStudentModel) =>
          schoolStudent.class_ref?.id == class_ref.id
      ).length;
    } else {
      return 0;
    }
  }

  sortTableData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction == '') {
      this.dataSource.data = data;
      return;
    }
    this.dataSource.data = data.sort(
      (a: SchoolClassTable, b: SchoolClassTable) => {
        if (sort.active == 'class') {
          return this.customClassSort(
            a.class_name,
            b.class_name,
            sort.direction
          );
        }
        const valueA = a[sort.active as keyof SchoolClassTable];
        const valueB = b[sort.active as keyof SchoolClassTable];
        const comparison = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        return sort.direction === 'asc' ? comparison : -comparison;
      }
    );
  }

  customClassSort(
    classA: string,
    classB: string,
    direction: 'asc' | 'desc' | ''
  ): number {
    // Split the class string into grade and suffix
    const [gradeA, ...suffixA] = classA.trim().split(/ +/);
    const [gradeB, ...suffixB] = classB.trim().split(/ +/);

    // Normalize grades (lowercase)
    const normalizedGradeA = gradeA.toLowerCase();
    const normalizedGradeB = gradeB.toLowerCase();

    // Compare grades using the gradeMapping object
    const gradeComparison =
      (this.utilSrvc_.gradeMapping[normalizedGradeA] ?? -1) -
      (this.utilSrvc_.gradeMapping[normalizedGradeB] ?? -1);

    if (gradeComparison === 0) {
      // If grades are the same, compare suffixes
      const suffixComparison = suffixA
        .join(' ')
        .localeCompare(suffixB.join(' '));
      return direction === 'asc' ? suffixComparison : -suffixComparison;
    }

    return direction === 'asc' ? gradeComparison : -gradeComparison;
  }

  navigateToClassDashboard(class_ref: DocumentReference) {
    console.log(class_ref);
    this.localStorageSrvc_.setItem(
      'selected_class',
      this.schoolClassesData.find(
        (value) => value.class_details.docRef == class_ref
      )?.class_details
    );
    this.localStorageSrvc_.setItem(
      'school_curriculum',
      this.schoolDetails.curriculum
    );
    this.router.navigate([
      `dashboard/school/${btoa(this.schoolDetails.docId!)}/class/${btoa(
        class_ref.id
      )}`,
    ]);
  }

  ngOnDestroy(): void {}
}
