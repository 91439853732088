import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';

export interface SuperAdminModel {
  user_id: string;
  access: SuperAdminAccessModel[];
  created_at: Date;
}

export interface SuperAdminAccessModel {
  city: string;
  district: string;
  state: string;
  country: string;
  school_type: string;
  active: boolean;
  vendor?: string;
}

export interface SuperadminGraphData {
  performance: number;
  attendance: number;
  district: string;
}

export interface SuperadminSyllabusGraphData {
  casted_hours:number;
  district?: string;
  school?:string
}

export const SuperAdminModelConverter = {
  toFirestore: function (superAdminModel: SuperAdminModel) {
    return {
      user_id: superAdminModel.user_id!,
      created_at: Timestamp.fromDate(superAdminModel.created_at),
      access: superAdminModel.access,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const superAdminModel: SuperAdminModel = {
      access: data['access'],
      user_id: data['user_id'],
      created_at: data['created_at'].toDate(),
    };
    return superAdminModel;
  },
};
