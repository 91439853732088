import { Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UtilsService } from '../../../../../services/utils.service';
import { SchoolTeacherAttendanceModel } from '../../../../../shared/models/school-teacher-attendance.model';
import {
  SchoolTeacherDetailModel,
  SchoolTeacherTable,
  SchoolTeacherClassModel,
} from '../../../../../shared/models/school-teacher.model';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../../shared/decorators/unsubscribe.decorator';

@Component({
  selector: 'teacher-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgIf,
    MatTooltipModule,
  ],
  templateUrl: './teacher-list.component.html',
  styleUrl: './teacher-list.component.scss',
})
@unsubscribe()
export class TeacherListComponent implements OnDestroy {
  @Input() schoolTeacherData: SchoolTeacherDetailModel[] = [];

  private utilSrvc_: UtilsService = inject(UtilsService);

  schoolTeachersTableData: SchoolTeacherTable[] = [];

  displayedColumns: string[] = [
    'name',
    'subjects',
    'classes',
    'attendance',
    'today_attendance',
  ];
  dataSource!: MatTableDataSource<SchoolTeacherTable>;

  subjectSub$: Subscription = new Subscription();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.subjectSub$ = this.sort?.sortChange.subscribe(() =>
      this.paginator.firstPage()
    );
  }

  ngOnInit(): void {
    this.populatingTeachersTable();
  }

  populatingTeachersTable() {
    for (let teacher of this.schoolTeacherData) {
      let rowTable: SchoolTeacherTable = {
        classes: this.utilSrvc_
          .sortClasses(
            [
              ...new Set(
                teacher.teacher_doc.classes.map(
                  (teacher_class: SchoolTeacherClassModel) =>
                    `${teacher_class.grade} ${teacher_class.section}`
                )
              ),
            ],
            'asc'
          )
          .map((a) => this.utilSrvc_.toTitleCase(a)),
        attendance: this.utilSrvc_.calulateAttendance(
          teacher.teacherAttendance as SchoolTeacherAttendanceModel[]
        ),
        present: this.utilSrvc_.checkAttendanceToday(
          teacher.teacherAttendance as SchoolTeacherAttendanceModel[]
        ),
        name:
          teacher.teacher_details.name.length != 0
            ? this.utilSrvc_.toTitleCase(teacher.teacher_details.name.join(' '))
            : '',
        subjects: [
          ...new Set(
            teacher.teacher_doc.classes
              .filter((a) => a.subject.trim() !== '') // Filter out empty strings
              .map((a) => a.subject)
              .map((a) => this.utilSrvc_.toTitleCase(a))
          ),
        ]
          .sort((a, b) => a.localeCompare(b))
          .map((a) => this.utilSrvc_.toTitleCase(a)),
      };
      this.schoolTeachersTableData.push(rowTable);
      this.schoolTeachersTableData.sort((a, b) => a.name.localeCompare(b.name));

      this.dataSource = new MatTableDataSource(this.schoolTeachersTableData);
    }
  }

  ngOnDestroy(): void {}
}
