import { Component, inject, Input } from '@angular/core';
import { AgCharts } from 'ag-charts-angular';
import {
  AgChartOptions,
  AgCategoryAxisOptions,
  AgNumberAxisOptions,
  AgChartCaptionOptions,
  AgAxisGridLineOptions,
  AgAxisGridStyle,
} from 'ag-charts-community';
import { SchoolClassData } from '../../../../../shared/models/school-class.model';
import { UtilsService } from '../../../../../services/utils.service';

@Component({
  selector: 'school-class-visuals',
  standalone: true,
  imports: [AgCharts],
  templateUrl: './school-class-visuals.component.html',
  styleUrl: './school-class-visuals.component.scss',
})
export class SchoolClassVisualsComponent {
  @Input() schoolClassData: SchoolClassData[] = [];
  public chartOptions!: AgChartOptions;

  schoolClassChartData: SchoolClassChart[] = [];

  private utisSrvc_: UtilsService = inject(UtilsService);

  constructor() {
    this.chartOptions = {
      // Data: Data to be displayed in the chart
      data: this.schoolClassChartData,

      // height: 272,
      // Series: Defines which chart type and data to use
      series: [
        { type: 'line', xKey: 'class_name', yKey: 'Performance' },
        { type: 'line', xKey: 'class_name', yKey: 'Attendance' },
      ],

      axes: [
        {
          position: 'bottom',
          type: 'category',
        } as AgCategoryAxisOptions,
        {
          position: 'left',
          type: 'number',
          keys: ['Performance'],
          gridLine: {
            style: [
              {
                stroke: '#464646',
              },
            ] as AgAxisGridStyle[],
          } as AgAxisGridLineOptions,
        } as AgNumberAxisOptions,
        {
          position: 'right',
          type: 'number',
          keys: ['Attendance'],
          gridLine: {
            style: [
              {
                stroke: '#464646',
              },
            ] as AgAxisGridStyle[],
          } as AgAxisGridLineOptions,
        } as AgNumberAxisOptions,
      ],

      background: {
        visible: false,
      },
      title: {
        enabled: true,
        text: 'Classwise Performance vs Attendance',
        fontStyle: 'oblique',
        fontFamily: 'Inter',
        textAlign: 'center',
      } as AgChartCaptionOptions,

      theme: 'ag-material',
    };
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.populatingChartData();
  }

  populatingChartData() {
    if (this.schoolClassData.length) {
      for (let classData of this.schoolClassData) {
        let schoolChartStat: SchoolClassChart = {
          class_name: this.utisSrvc_.toTitleCase(
            `${classData.class_details.name} ${classData.class_details.section}`
          ),
          Performance:
            classData.class_assessments.length > 0
              ? this.utisSrvc_.calculatePerformance(classData.class_assessments)
              : 0,
          Attendance:
            classData.class_attendance.length > 0
              ? this.utisSrvc_.calulateAttendance(classData.class_attendance)
              : 0,
        };
        this.schoolClassChartData.push(schoolChartStat);
      }
      this.schoolClassChartData.sort((a, b) =>
        this.customClassSort(a.class_name, b.class_name, 'asc')
      );
      this.chartOptions.data = this.schoolClassChartData;
    }
  }

  customClassSort(
    classA: string,
    classB: string,
    direction: 'asc' | 'desc' | ''
  ): number {
    // Split the class string into grade and suffix
    const [gradeA, ...suffixA] = classA.trim().split(/ +/);
    const [gradeB, ...suffixB] = classB.trim().split(/ +/);

    // Normalize grades (lowercase)
    const normalizedGradeA = gradeA.toLowerCase();
    const normalizedGradeB = gradeB.toLowerCase();

    // Compare grades using the gradeMapping object
    const gradeComparison =
      (this.utisSrvc_.gradeMapping[normalizedGradeA] ?? -1) -
      (this.utisSrvc_.gradeMapping[normalizedGradeB] ?? -1);

    if (gradeComparison === 0) {
      // If grades are the same, compare suffixes
      const suffixComparison = suffixA
        .join(' ')
        .localeCompare(suffixB.join(' '));
      return direction === 'asc' ? suffixComparison : -suffixComparison;
    }

    return direction === 'asc' ? gradeComparison : -gradeComparison;
  }
}

export interface SchoolClassChart {
  class_name: string;
  Performance: number;
  Attendance: number;
}
