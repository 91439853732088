import {
  Timestamp,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from '@angular/fire/firestore';
import { SchoolModelCurriculum } from './school.model';
import { UserModel } from './user.model';

export interface SchoolTimetableModel {
  class_doc_ids: string[];
  created_at: Date;
  modified_at: Date | null;
  curriculum: SchoolModelCurriculum;
  docId?: string;
}

export interface SchoolTimetableDaysModel {
  periods: TimetablePeriodModel[];
  docId?: string;
}

export interface SchoolTimetableWithDays {
  timetable: SchoolTimetableModel;
  days: { [key: string]: SchoolTimetableDaysModel };
}

export interface TimetablePeriodModel {
  start: string;
  end: string;
  type: string;
  period: string;
  teachers: TimetablePeriodTeacherModel[];
}

export interface TimetablePeriodTeacherModel {
  teacher_id: string;
  class_id: string;
  subject: string;
}

export interface TimetablePeriodTeacherDetailModel {
  teacher_id: string;
  class_id: string;
  subject: string;
  teacher_details: UserModel;
}

export interface ClassTimetablePeriodForDisplay {
  start: string;
  end: string;
  type: string;
  period: string;
  teacher: string;
  subject: string;
}

export const SchoolTimetableModelConverter = {
  toFirestore: function (schoolTimetableModel: SchoolTimetableModel) {
    return {
      class_doc_ids: schoolTimetableModel.class_doc_ids,
      created_at: Timestamp.fromDate(schoolTimetableModel.created_at),
      modified_at: schoolTimetableModel.modified_at
        ? Timestamp.fromDate(schoolTimetableModel.modified_at)
        : null,
      curriculum: schoolTimetableModel.curriculum,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolTimetableModel: SchoolTimetableModel = {
      class_doc_ids: data['class_doc_ids'],
      created_at: data['created_at'].toDate(),
      modified_at: data['modified_at'] ? data['modified_at'].toDate() : null,
      curriculum: data['curriculum'],
      docId: snapshot.id,
    };
    return schoolTimetableModel;
  },
};

export const SchoolTimetableDaysModelConverter = {
  toFirestore: function (schoolTimetableDaysModel: SchoolTimetableDaysModel) {
    return {
      periods: schoolTimetableDaysModel.periods,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolTimetableDaysModel: SchoolTimetableDaysModel = {
      periods: data['periods'],
      docId: snapshot.id,
    };
    return schoolTimetableDaysModel;
  },
};
