<!-- <div>
  <h1>HURREY AMNS SUPERADMIN</h1>
  <button mat-icon-button class="theme-toggle" aria-label="Change theme" [matMenuTriggerFor]="themeMenu">
    <mat-icon>
      {{(isDark$ | async) === true ? "dark_mode" : "light_mode" }}
    </mat-icon>
  </button>
  <mat-menu #themeMenu="matMenu">
    <button mat-menu-item (click)="changeTheme('auto')">System</button>
    <button mat-menu-item (click)="changeTheme('light')">Light</button>
    <button mat-menu-item (click)="changeTheme('dark')">Dark</button>
  </mat-menu>
  <div class="demo-buttons density-xs">
    <button mat-button>Basic</button>
    <button mat-raised-button>Raised</button>
    <button mat-stroked-button>Stroked</button>
    <button mat-flat-button>Flat</button>
  </div>
</div> -->
<div class="container h-full">
  <router-outlet></router-outlet>
</div>
