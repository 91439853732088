export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyANCDEETPYGzgu4iwmenGLZxddjglBoVnU',
    authDomain: 'hurreytech-b72f1.firebaseapp.com',
    projectId: 'hurreytech-b72f1',
    storageBucket: 'hurreytech-b72f1.appspot.com',
    messagingSenderId: '866357892195',
    appId: '1:866357892195:web:8bb2d6e77be8ae3b88d456',
    measurementId: 'G-L9ZH5ENL3V',
  },
};
