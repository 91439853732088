import { Component, inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { SchoolStudentAssessmentModel } from '../../../../../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModel } from '../../../../../shared/models/school-student-attendance.model';
import {
  SchoolStudentDetailsModel,
  SchoolStudentTable,
} from '../../../../../shared/models/school-student.model';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { UtilsService } from '../../../../../services/utils.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { SelectAllDirective } from '../../../../../shared/directives/select-all.directive';
import { unsubscribe } from '../../../../../shared/decorators/unsubscribe.decorator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'student-list',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    NgIf,
    ReactiveFormsModule,
    NgFor,
    TitleCasePipe,
    SelectAllDirective,
  ],
  templateUrl: './student-list.component.html',
  styleUrl: './student-list.component.scss',
})
@unsubscribe()
export class StudentListComponent implements OnDestroy {
  @Input() schoolAssessments: SchoolStudentAssessmentModel[] = [];
  @Input() schoolAttendanceDocs: SchoolStudentAttendanceModel[] = [];
  @Input() schoolStudentData: SchoolStudentDetailsModel[] = [];

  private utilSrvc_: UtilsService = inject(UtilsService);

  displayedColumns: string[] = [
    'name',
    'class',
    'roll',
    'performance',
    'attendance',
    'today_attendance',
  ];

  dataSource!: MatTableDataSource<SchoolStudentTable>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  schoolStudentTableData: SchoolStudentTable[] = [];

  selectedClassesFormControl: FormControl = new FormControl(['All']);

  sortSub$: Subscription = new Subscription();
  valueChangesSub$: Subscription = new Subscription();

  classes: string[] = [];

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sortSub$ = this.sort.sortChange.subscribe(() => {
      this.paginator.firstPage();
      this.sortTableData(this.sort);
    });
    this.dataSource.filterPredicate = this.createFilter();
  }

  ngOnInit(): void {
    this.populatingStudentsData();
    this.valueChangesSub$ =
      this.selectedClassesFormControl.valueChanges.subscribe((el) => {
        if (el.length == 0) {
          this.dataSource.data = [];
        } else {
          this.dataSource.data = this.schoolStudentTableData;
        }
      });
  }

  populatingStudentsData() {
    for (let student of this.schoolStudentData) {
      let rowTable: SchoolStudentTable = {
        name:
          student.student_details.name.length != 0
            ? this.utilSrvc_.toTitleCase(student.student_details.name.join(' '))
            : '',
        class: `${student.student_doc.grade} ${student.student_doc.section}`,
        roll: student.student_doc.roll,
        attendance: this.utilSrvc_.calulateAttendance(
          this.schoolAttendanceDocs.filter(
            (attendanceDoc: SchoolStudentAttendanceModel) =>
              student.student_doc.user_id == attendanceDoc.student_id
          )
        ),
        performance: this.utilSrvc_.calculatePerformance(
          this.schoolAssessments.filter(
            (assessment: SchoolStudentAssessmentModel) =>
              student.student_doc.user_id == assessment.student_id
          )
        ),
        present: this.utilSrvc_.checkAttendanceToday(
          this.schoolAttendanceDocs.filter(
            (attendanceDoc: SchoolStudentAttendanceModel) =>
              student.student_doc.user_id == attendanceDoc.student_id
          )
        ),
      };
      this.classes.push(rowTable.class);
      this.schoolStudentTableData.push(rowTable);
    }
    this.schoolStudentTableData.sort((a, b) => a.name.localeCompare(b.name));
    this.classes = Array.from(new Set(this.classes));
    this.classes = this.utilSrvc_.sortClasses(this.classes, 'asc');
    this.selectedClassesFormControl.setValue(this.classes);
    this.dataSource = new MatTableDataSource(this.schoolStudentTableData);
  }

  createFilter(): (data: SchoolStudentTable, filter: string) => boolean {
    return (data: SchoolStudentTable, filter: string): boolean => {
      // const filterClasses = filter.split('|').map((item) => item.toLowerCase());
      // return filterClasses.some((className) =>
      //   data.class.toLowerCase().includes(className)
      // );
      if (!filter) return true;

      const filterClasses = filter.split('|').map((item) => item.toLowerCase());

      // Check if the class matches the filter
      return filterClasses.some((className) =>
        data.class.toLowerCase().includes(className)
      );
    };
  }

  filterTable() {
    this.dataSource.filter =
      this.selectedClassesFormControl.value.join('|') || '';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    // Apply sorting after filtering
    const activeSort = this.sort.active;
    const sortDirection = this.sort.direction;

    if (activeSort && sortDirection) {
      this.sortTableData({ active: activeSort, direction: sortDirection });
    }
  }

  sortTableData(sort: Sort) {
    const filterData = this.dataSource.filteredData.slice();
    if (!sort.active || sort.direction == '') {
      this.dataSource.data = filterData;
      return;
    }
    this.dataSource.data = filterData.sort(
      (a: SchoolStudentTable, b: SchoolStudentTable) => {
        if (sort.active == 'class') {
          return this.customClassSort(a.class, b.class, sort.direction);
        }
        const valueA = a[sort.active as keyof SchoolStudentTable];
        const valueB = b[sort.active as keyof SchoolStudentTable];
        const comparison = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        return sort.direction === 'asc' ? comparison : -comparison;
      }
    );
    // this.dataSource = new MatTableDataSource(this.dataSource.data);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  customClassSort(
    classA: string,
    classB: string,
    direction: 'asc' | 'desc' | ''
  ): number {
    // Split the class string into grade and suffix
    const [gradeA, ...suffixA] = classA.trim().split(/ +/);
    const [gradeB, ...suffixB] = classB.trim().split(/ +/);

    // Normalize grades (lowercase)
    const normalizedGradeA = gradeA.toLowerCase();
    const normalizedGradeB = gradeB.toLowerCase();

    // Compare grades using the gradeMapping object
    const gradeComparison =
      (this.utilSrvc_.gradeMapping[normalizedGradeA] ?? -1) -
      (this.utilSrvc_.gradeMapping[normalizedGradeB] ?? -1);

    if (gradeComparison === 0) {
      // If grades are the same, compare suffixes
      const suffixComparison = suffixA
        .join(' ')
        .localeCompare(suffixB.join(' '));
      return direction === 'asc' ? suffixComparison : -suffixComparison;
    }

    return direction === 'asc' ? gradeComparison : -gradeComparison;
  }

  ngOnDestroy(): void {}
}
