import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { UtilsService } from '../../../../../services/utils.service';
import { ClassStudentsTable } from '../../../../../shared/models/school-class.model';
import { SchoolStudentAttendanceModel } from '../../../../../shared/models/school-student-attendance.model';
import { SchoolStudentDetailsModel } from '../../../../../shared/models/school-student.model';
import { SchoolStudentAssessmentModel } from '../../../../../shared/models/school-student-assessment.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'class-students-list',
  standalone: true,
  imports: [MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './class-students-list.component.html',
  styleUrl: './class-students-list.component.scss',
})
export class ClassStudentsListComponent implements OnInit, AfterViewInit {
  @Input() classStudents: SchoolStudentDetailsModel[] = [];

  @Input() classAttendance: SchoolStudentAttendanceModel[] = [];

  @Input() classAssessments: SchoolStudentAssessmentModel[] = [];

  displayedColumns: string[] = [
    'roll',
    'name',
    'performance',
    'attendance',
    'today_attendance',
  ];

  sortingSub$: Subscription = new Subscription();

  classStudentTableData: ClassStudentsTable[] = [];

  private utilSrvc_: UtilsService = inject(UtilsService);

  dataSource!: MatTableDataSource<ClassStudentsTable>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sortingSub$ = this.sort?.sortChange.subscribe(() =>
      this.paginator.firstPage()
    );
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.populateClassStudentData();
  }

  populateClassStudentData() {
    for (let student of this.classStudents) {
      let rowTable: ClassStudentsTable = {
        name:
          student.student_details.name.length != 0
            ? this.utilSrvc_.toTitleCase(student.student_details.name.join(' '))
            : '',
        roll: student.student_doc.roll,
        attendance: this.utilSrvc_.calulateAttendance(
          this.classAttendance.filter(
            (att) => att.student_id == student.student_details.docId
          )
        ),
        performance: this.utilSrvc_.calculatePerformance(
          this.classAssessments.filter(
            (asm) => asm.student_id == student.student_details.docId
          )
        ),
        present: this.utilSrvc_.checkAttendanceToday(
          this.classAttendance.filter(
            (att) => att.student_id == student.student_details.docId
          )
        ),
      };
      this.classStudentTableData.push(rowTable);
    }
    this.classStudentTableData.sort((a, b) => a.roll - b.roll);
    console.log(this.classStudentTableData);
    this.dataSource = new MatTableDataSource(this.classStudentTableData);
  }
}
