@if (dataLoaded) {
<div class="flex flex-col gap-5">

  <h1 class="text-hurrey-32 md:text-hurrey-36 lg:text-hurrey-48" style="margin-bottom:0px !important">
    {{schoolDetails.name.join(' ')|titlecase}}</h1>
  <div class="flex flex-col md:flex-row  gap-2 md:gap-8">
    <div class="flex items-center gap-2">
      <mat-icon>location_on</mat-icon>
      <span>
        {{schoolAddress()}}
      </span>
    </div>
    @if (schoolDetails.mobile.length) {
    <div class="flex gap-2 items-center">
      <mat-icon>smartphone</mat-icon>
      <a [href]="returnPhoneNumber(schoolDetails.mobile[0])?.telLink">+91 {{
        returnPhoneNumber(schoolDetails.mobile[0])?.phone}}</a>
    </div>

    }

    @if (schoolDetails.email.length) {
    <div class="flex gap-2 items-center">
      <mat-icon>email</mat-icon>
      <a [href]="returnEmail(schoolDetails.email[0])">{{schoolDetails.email[0]}}</a>
    </div>
    }
  </div>



  <div class="grid grid-cols-12 gap-3 lg:gap-4">
    <div class="col-span-6 lg:col-span-3 lg:col-start-1 data-card p-2 flex flex-col gap-3 relative ">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Students</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>person</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.students}}</p>
    </div>
    <div class="col-span-6 lg:col-span-3 lg:col-start-4 data-card p-2 flex flex-col gap-3 relative">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Teachers</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>supervisor_account</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.teachers}}</p>
    </div>

    <div class="col-span-6 lg:col-span-3 lg:col-start-1  data-card p-2 flex flex-col gap-3 relative">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Admins</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>manage_accounts</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.admins}}</p>
    </div>

    <div class="col-span-6 lg:col-span-3 lg:col-start-4 data-card p-2 flex flex-col gap-3 relative">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Classes</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>house_siding</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.classes}}</p>
    </div>
    <div class="col-span-6 lg:col-span-3 lg:col-start-1 data-card p-2 flex flex-col gap-3 relative">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Board</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>history_edu</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.board[0]|uppercase}}</p>
    </div>
    <div class="col-span-6 lg:col-span-3 lg:col-start-4 data-card p-2 flex flex-col gap-3 relative">
      <div class="flex justify-between items-center">
        <p class="card_head opacity-50">Medium</p>
        <div class="card_icon bg-[#d3d3d34a]">
          <mat-icon>language</mat-icon>
        </div>
      </div>
      <p class="text-hurrey-20 md:text-hurrey-24 lg:text-hurrey-28">{{schoolDetails.medium[0]|titlecase}}</p>
    </div>
    @if(schoolRecommendations.length){
    <div class="col-span-12 lg:col-span-6 lg:col-start-7 lg:row-span-3 row-start-5 lg:row-start-1 recommendation_box">

      <div class="mb-3">Recommendations</div>
      <ul class="flex flex-col gap-2">
        @for (recommendation of schoolRecommendations; track $index) {
        <li class="flex gap-3 items-center">
          <div>
            <mat-icon>recommend</mat-icon>
          </div>
          <more-less class="flex-1" [text]="recommendation.message" [wordLimit]="40"></more-less>
        </li>
        }
      </ul>
    </div>
    }


  </div>


  <div class="grid grid-cols-12 gap-3">
    <div class="col-span-12 lg:col-span-6">
      <school-today [schoolClassData]="schoolClassData" [schoolTeachersData]="schoolTeacherData"
        [schoolStudentsData]="schoolStudentData" [schoolTimeTableData]="schoolTimetableData"></school-today>
    </div>
    <div class="col-span-12 lg:col-span-6">
      <school-class-visuals [schoolClassData]="schoolClassData"></school-class-visuals>
    </div>
  </div>






  <section id="class_details">
    @if(schoolClassData.length!=0){
    <class-list [schoolClassesData]="schoolClassData" [schoolStudents]="schoolStudents"
      [schoolDetails]="schoolDetails"></class-list>
    }@else{
    <p class="font-bold">Classes</p>
    <div class="flex justify-center items-center flex-col">
      <img src="../../../assets/images/no_data.svg" alt="No Class Data" width="300" height="300">
      <p>No classes data found</p>
    </div>
    }
  </section>

  <section id="student_details">
    @if(schoolStudentData.length!=0){
    <student-list [schoolAssessments]="schoolAssessments" [schoolAttendanceDocs]="schoolAttendanceDocs"
      [schoolStudentData]="schoolStudentData"></student-list>
    }@else {
    <p class="font-bold">Students</p>
    <div class="flex justify-center items-center flex-col">
      <img src="../../../assets/images/no_data.svg" alt="No Students Data" width="300" height="300">
      <p>No students data found</p>
    </div>
    }
  </section>

  <section id="teacher_details">
    @if(schoolTeacherData.length){
    <teacher-list [schoolTeacherData]="schoolTeacherData"></teacher-list>
    }@else {
    <div>
      <p class="font-bold">Teachers</p>
      <div class="flex justify-center items-center flex-col">
        <img src="../../../assets/images/no_data.svg" alt="No Teachers Data" width="300" height="300">
        <p>No teachers data found</p>
      </div>
    </div>
    }
  </section>

</div>
}@else {
<div class="h-full w-full flex justify-center items-center">
  <mat-spinner color="primary"></mat-spinner>
</div>
}