import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'forgot-password',
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  loading!: boolean;
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  private authSrvc_: AuthService = inject(AuthService);

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Please enter your email';
    } else if (this.email.hasError('email')) {
      return 'Email entered is invalid';
    }

    return '';
  }

  resetPassword() {
    this.loading = true;
    this.authSrvc_
      .resetPassword(this.email.value)
      .then(() => {
        this.loading = false;
        this.snackbarSrvc_.openSuccessSnackbar(
          `Reset link has been sent to ${this.email.value}`
        );
        this.authSrvc_.logOut();
      })
      .catch(() => {
        this.loading = false;
        this.snackbarSrvc_.openErrorSnackbar(
          'Error occured while sending the mail'
        );
      });
  }
}
