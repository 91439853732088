<div class="mat-elevation-z8 table_container">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="roll">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Roll </th>
      <td mat-cell *matCellDef="let row"> {{row.roll}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>



    <!-- Fruit Column -->
    <ng-container matColumnDef="performance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Performance </th>
      <td mat-cell *matCellDef="let row">
        @if(row.performance>=0){
        <span>{{row.performance}}%</span>
        }@else {
        <span>No Performance Data</span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="attendance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Attendance </th>
      <td mat-cell *matCellDef="let row">
        @if(row.attendance>=0){
        <span>{{row.attendance}}%</span>
        }@else {
        <span>No Attendance Data</span>
        }
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="today_attendance">
      <th mat-header-cell *matHeaderCellDef> Today Attendance </th>
      <td mat-cell *matCellDef="let row">
        @if(row.present!=-1){
        @if (row.present) {
        <span class="text-amber_100">Present</span>
        }@else {
        <span class="text-rose_100">Absent</span>
        }
        }@else {
        <span>Not marked</span>
        }

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25]" aria-label="Select page of students"></mat-paginator>
</div>