import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from '@angular/fire/firestore';

export interface SchoolModel {
  name: string[];
  school_id: string;
  mobile: string[];
  email: string[];
  address: SchoolModelAddress;
  board: string[];
  medium: string[];
  curriculum: SchoolModelCurriculum;
  created_at: Date | null;
  modified_at: Date | null;
  active: boolean;
  deleted: boolean;
  teachers: number;
  classes: number;
  admins: number;
  students: number;
  created_by: string;
  profile_url: string;
  username: string;
  amenities: string[];
  school_type: string;
  school_category: string;
  coed_type: string;
  vendor?: string;
  docId?: string;
}

export interface SchoolModelAddress {
  city: string;
  state: string;
  country: string;
  pincode: string;
  address_line: string;
  locality: string;
  district: string;
  longitude: number;
  latitude: number;
}

export interface SchoolModelCurriculum {
  start_month: number;
  end_month: number;
  start_year: number;
  end_year: number;
}

export interface SchoolTableData {
  school_name: string;
  classes: number;
  teachers: number;
  admins: number;
  students: number;
  address: SchoolModelAddress;
  school_doc_id: string;
}

export const SchoolModelConverter = {
  toFirestore: function (schoolModel: SchoolModel) {
    return {
      name: schoolModel.name,
      school_id: schoolModel.school_id!,
      mobile: schoolModel.mobile,
      email: schoolModel.email,
      address: schoolModel.address,
      board: schoolModel.board,
      medium: schoolModel.medium,
      curriculum: schoolModel.curriculum,
      created_at: schoolModel.created_at
        ? Timestamp.fromDate(schoolModel.created_at)
        : null,
      modified_at: schoolModel.modified_at
        ? Timestamp.fromDate(schoolModel.modified_at)
        : null,
      active: schoolModel.active,
      deleted: schoolModel.deleted,
      teachers: schoolModel.teachers,
      classes: schoolModel.classes,
      admins: schoolModel.admins,
      students: schoolModel.students,
      created_by: schoolModel.created_by,
      profile_url: schoolModel.profile_url,
      username: schoolModel.username,
      amenities: schoolModel.amenities,
      school_type: schoolModel.school_type,
      school_category: schoolModel.school_category,
      coed_type: schoolModel.coed_type,
      vendor: schoolModel.vendor,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) {
    const data = snapshot.data(options);
    const schoolModel: SchoolModel = {
      name: data['name'],
      school_id: data['school_id'],
      mobile: data['mobile'],
      email: data['email'],
      address: data['address'],
      board: data['board'],
      medium: data['medium'],
      curriculum: data['curriculum'],
      created_at: data['created_at'] ? data['created_at'].toDate() : null,
      modified_at: data['modified_at'] ? data['modified_at'].toDate() : null,
      active: data['active'],
      deleted: data['deleted'],
      teachers: data['teachers'],
      classes: data['classes'],
      admins: data['admins'],
      students: data['students'],
      created_by: data['created_by'],
      profile_url: data['profile_url'],
      username: data['username'],
      amenities: data['amenities'],
      school_type: data['school_type'],
      school_category: data['school_category'],
      coed_type: data['coed_type'],
      vendor: data['vendor'],
      docId: snapshot.id,
    };
    return schoolModel;
  },
};
