<div class="flex flex-col gap-3">
  <p class="font-bold">Teachers</p>

  <div class="mat-elevation-z8 table_container">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="name" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Teacher </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Progress Column -->
      <ng-container matColumnDef="subjects">
        <th mat-header-cell *matHeaderCellDef> Subjects </th>

        <td mat-cell *matCellDef="let row" [matTooltip]="row.subjects">
          @if (row.subjects.length) {
          {{(row.subjects?.[0]) || ''}}
          <span *ngIf="(row.subjects.length || 0) > 1" class="example-additional-selection">
            (+{{(row.subjects?.length || 0) - 1}} {{row.subjects?.length === 2 ?
            'other' : 'others'}})
          </span>
          }@else{
          <span>No subjects mapped</span>
          }
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="classes">
        <th mat-header-cell *matHeaderCellDef> Classes </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.classes">
          @if (row.classes.length) {
          {{(row.classes?.[0]) || ''}}
          <span *ngIf="(row.classes.length || 0) > 1" class="example-additional-selection">
            (+{{(row.classes?.length || 0) - 1}} {{row.classes?.length === 2 ?
            'other' : 'others'}})
          </span>
          }@else {
          <span>No class assigned</span>
          }
        </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.attendance>=0){
          <span>{{row.attendance}}%</span>
          }@else {
          <span>No attendance data</span>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="today_attendance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Today's Attendance </th>
        <td mat-cell *matCellDef="let row">
          @if(row.present!=-1){
          @if (row.present) {
          <span class="text-amber_100">Present</span>
          }@else {
          <span class="text-rose_100">Absent</span>
          }
          }@else {
          <span>Not marked</span>
          }
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr> -->
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of teachers"></mat-paginator>
  </div>


</div>